import axiosClient from "../axiosClient";

const orderAPI = {
  getOrders: (page, params) => {
    const url = `/admin/orders/page/${page}${params}&limit=30`;
    return axiosClient.get(url);
  },
  getOrderDetail: (code) => {
    const url = `admin/orders/${code}`;
    return axiosClient.get(url, code);
  },
  getAttendants: (code, page, queryString = "") => {
    const url = `admin/orders/${code}/page/${page}?${queryString}`;
    return axiosClient.get(url);
  },
  getEventOrdersExportData: (eventId, filter) => {
    const url = `/admin/orders/event/${eventId}/export?${filter}`;
    return axiosClient.get(url);
  },
  getShopOrdersExportData: (filter) => {
    const url = `/admin/shop/orders/export?${filter}`;
    return axiosClient.get(url);
  },
  getEventOrderItemsExportData: (eventId, filter) => {
    const url = `/admin/order-items/event/${eventId}/export?${filter}`;
    return axiosClient.get(url);
  },
  getShopOrderItemsExportData: (filter) => {
    const url = `/admin/shop/order-items/export?${filter}`;
    return axiosClient.get(url);
  },
  updateLogNote: (orderCode, data) => {
    const url = `admin/orders/${orderCode}/log-note`;
    return axiosClient.post(url, data);
  },
  resendPaymentSuccessEmail: (order_code, data) => {
    const url = `/admin/email/payment-success/orders/${order_code}`;
    return axiosClient.post(url, data);
  },
  resendQRCodeEmail: (order_code, data) => {
    const url = `/admin/email/qr-code/orders/${order_code}`;
    return axiosClient.post(url, data);
  },
  resendAttendantPaymentSuccessEmail: (order_code, participant_id, data) => {
    const url = `/admin/email/payment-success/orders/${order_code}/participant/${participant_id}`;
    return axiosClient.post(url, data);
  },
  updateAttendantInfo: (orderCode, attendantId, data) => {
    const url = `admin/orders/${orderCode}/attendants/${attendantId}`;
    return axiosClient.put(url, data);
  },
  updateBuyerInfo: (orderCode, data) => {
    const url = `admin/orders/${orderCode}/buyer`;
    return axiosClient.put(url, data);
  },
  updateOrderStatus: (order_code, data) => {
    const url = `/admin/orders/${order_code}`;
    return axiosClient.put(url, data);
  },
  updatePaymentStatus: (order_code, data) => {
    const url = `/admin/orders/${order_code}/payment-method`;
    return axiosClient.put(url, data);
  },
  createTax: (order_code, data) => {
    const url = `/admin/orders/${order_code}/tax`;
    return axiosClient.post(url, data);
  },
  updateTax: (order_code, data) => {
    const url = `/admin/orders/${order_code}/tax`;
    return axiosClient.put(url, data);
  },
  refund: (order_code, transactionId, data) => {
    const url = `/admin/orders/${order_code}/transactions/${transactionId}/request-refund`;
    return axiosClient.post(url, data);
  },
};

export default orderAPI;
