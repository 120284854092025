import React, { Component } from "react";
import Layout from "../components/Layout";

export default class CreateUser extends Component {
    render() {
        return(
          <Layout>
            <div className="page-wrapper">
              <div className="page-container">
                <div className="main-content">
                  <div className="section__content section__content--p30">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="copyright" style={{textAlign: 'center', paddingTop: '25%'}}>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        );
    }
}