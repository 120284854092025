import { getCurrentUser } from "./auth";

export const currencyFormat = (nStr, suffix = '₫') => {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, `$1.$2`);
    }
    return `${x1}${x2} ${suffix}`;
  };
  
  export const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  export const paginate = (array, page_number, pageSize = 15) => {
    return array.slice((page_number - 1) * pageSize, page_number * pageSize)
  }

  export const checkPermission = (object, action) => {
    const user = getCurrentUser();

    try {
      const { roles } = user.result || {};
      const userRole = roles.find((r) => r.object === object);
      if (!userRole) return false;

      const userRoleArr = userRole.action.split(',');
      return (userRoleArr.includes('*') || userRoleArr.includes(action)) ? true : false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  export const getOrderStatusString = (value) => {
    switch (value) {
      case 0:
        return "Đợi thanh toán";
      case 1:
        return "Đặt hàng thành công";
      case 2:
        return "Đợi thanh toán";
      case 3:
        return 'Hủy đơn hàng';
      case 4:
        return 'Hoàn tiền';
      case 5:
        return 'Chờ hoàn tiền';
      default:
        return "Không rõ trạng thái đơn hàng"
    }
  };

  export const getOrderStatus = (value) => {
    switch (value) {
      case 'dat_hang_thanh_cong':
        return 1;
      case 'doi_thanh_toan':
        return 2;
      case 'da_huy':
        return 3;
      case 'hoan_tien':
        return 4;
      default:
        return 0
    }
  };

  export const getPaymentMethodToString = (value) => {
    switch (value) {
      case 'credit':
        return "Thẻ tín dụng (Credit)";
      case 'credit_global':
        return "Thẻ tín dụng phát hành ngoài VN";
      case 'atm':
        return "Thẻ ngân hàng (ATM)";
      case 'zalopay':
        return 'ZaloPay';
      case 'viet_qr':
        return 'Viet QR';
      case 'vnpay':
        return 'QRCode';
      case 'bank_transfer':
        return 'Chuyển khoản ngân hàng';
      case 'voucher':
        return 'Voucher';
      case 'momo':
        return 'Momo';
      case 'partner_vnpay':
        return 'Partner Vnpay';
      case 'vn_pay_b':
        return 'VNAY';
      case 'bnpl':
        return 'Buy now pay later';
      case 'viettel_money':
        return 'Viettel Money - VietQR';
      case 'v_atm':
        return 'Viettel Money - Thẻ ATM Nội địa';
      case 'v_credit':
        return 'Viettel Money - Thẻ Quốc tế';
      case 'payoo_atm':
        return 'Payoo - Thẻ ATM Nội địa';
      case 'payoo_credit':
        return 'Payoo - Thẻ Quốc tế';
      default:
        return "Thẻ tín dụng (Credit)"
    }
  }

