import React from "react";
import { Col, Row } from "antd";
import * as classes from '../OrderDetail.module.css';
import { currencyFormat } from "../../../../util/helpers";
import { get } from "lodash";

const PurchasedProducts = ({ orderDetail, usedCode }) => {
  return (
    <Row gutter={[16, 16]} className={classes.infoContainer}>
      <Col className={classes.label} span={24}>Sản phẩm đã mua</Col>
      <Col span={24}>
        <table className="table table-hover">
          <tbody>
            <tr className={classes.backgroundTr}>
              <th scope="col" className={classes.backgroundTh}>#</th>
              <th scope="col" className={classes.backgroundTh}>Tên sản phẩm</th>
              <th scope="col" className={classes.backgroundTh}>Số lượng</th>
              <th scope="col" className={classes.backgroundTh}>Giá sản phẩm</th>
              <th scope="col" className={classes.backgroundTh}>Coupon</th>
              <th scope="col" className={classes.backgroundTh}>Tổng tiền</th>
              <th scope="col" className={classes.backgroundTh}>Code đã sử dụng</th>
            </tr>
            {orderDetail && orderDetail.items && orderDetail.items.map((order, index) => {
              return (
                <tr key={order.product_id}>
                  <td className={classes.backgroundTd} >
                    <span>{index + 1}</span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span> {order.product_name_vi} </span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span> {order.quantity} </span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span> {currencyFormat(get(order, 'product_price', 0))}</span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span> {currencyFormat(get(order, 'discount_amount', 0))}</span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span> {currencyFormat(get(order, 'grand_total', 0))}</span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span> {usedCode} </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

      </Col>
    </Row>
  )
}

export default PurchasedProducts;