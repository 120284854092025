import { Col, Divider, Row } from "antd";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { ROLE_VALUES, TAX_INFO_FIELDS } from "../../../../util/constants";
import * as classes from '../OrderDetail.module.css';

import {
  EditTwoTone,
  SaveTwoTone,
} from '@ant-design/icons';
import { checkPermission } from "../../../../util/helpers";

const TaxInfo = ({ orderDetail, _submitTaxData }) => {

  const [isEditTaxData, setIsEditTaxData] = useState(false);
  const [taxData, setTaxData] = useState(orderDetail.tax);

  const _handleTaxInputChange = (key, value) => {
    const updatedTaxData = {...taxData};
    updatedTaxData[key] = value;
    setTaxData(updatedTaxData);
  };
  
  const _handleSubmitTaxData = async () => {

    if (!isEditTaxData) return setIsEditTaxData(true);
    const response = await _submitTaxData(taxData);
    if (response) {
      setIsEditTaxData(false);
    }
  }

  return !isEmpty(orderDetail.tax.company_name) ? (
    <>
      <Row gutter={[16, 16]} className={classes.infoContainer}>
        <Col className={classes.label} span={24}>
          Thông tin hóa đơn
          {checkPermission('list', ROLE_VALUES.VAT_ACTION) ? (
            <div 
              style={{display: 'flex', alignItems: 'center'}} 
              onClick={_handleSubmitTaxData}
            >
              {isEditTaxData 
              ? <SaveTwoTone className={classes.editBuyerIcon} />  
              : <EditTwoTone className={classes.editBuyerIcon} />}
            </div>
          ) : null}

        </Col>
        {TAX_INFO_FIELDS.map((field, index) => (
          <Col span={12} key={index}>
            {field.label} : 
            {isEditTaxData ? (
              <input 
                className={classes.editInput}
                style={{width: 400}}
                placeholder={field.label} 
                type="text" 
                value={taxData[field.key]}
                onChange={(e) => _handleTaxInputChange(field.key, e.target.value)} 
              />
            ) : (
              <span className={classes.buyerLabel}> {taxData[field.key]} </span>
            )}
          </Col>
        ))}
      </Row>
      <Divider />
    </>
  ) : null;
}

export default TaxInfo;