import axiosClient from "../axiosClient";

const contactAPI = {
  getContacts: (page, status, q, support_center_category_id) => {
    const url = `/admin/support-center-other-questions/page/${page}?status=${status}&q=${q}&limit=&support_center_category_id=${support_center_category_id}`;
    return axiosClient.get(url, page);
  },
  updateProcessContact: (id, step) => {
    const url = `/admin/support-center-other-questions/${id}/process-step/${step}`;
    return axiosClient.put(url, step);
  },
  getCategoriesList: () => {
    const url = `/admin/support-center-categories`;
    return axiosClient.get(url);
  }
};

export default contactAPI;
