import React, { Component } from "react";
import { withRouter } from 'react-router';
import { Layout, Menu } from 'antd';
import logo from "../images/actiup-white.png";
import {
  OrderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { checkPermission } from "../util/helpers";
import { ROLE_VALUES } from "../util/constants";

const { Sider } = Layout;

class Menuslide extends Component {

  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  getDefaultSelectedKeys() {
    const path = this.props.location.pathname
    switch (path) {
      case '/orders':
        return ['1']
      case '/contacts':
        return ['2']
      case '/users':
        return ['3']
      default:
        return ['0']
    }
  }
  render() {
    return (
      <Sider>
        <div
        style={{cursor: 'pointer', textAlign: 'center'}} 
        onClick={() => {this.props.history.push('/index')}}
        > 
        <img src={logo} style={{maxWidth: '100%', height: 60, padding: '10px'}} alt="" />
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={this.getDefaultSelectedKeys()}>
          {checkPermission('list', ROLE_VALUES.VIEW) ? (
            <Menu.Item 
              key="1" 
              icon={<OrderedListOutlined />}
              onClick={() => {this.props.history.push('/orders')}}>
              Orders
            </Menu.Item>
          ) : null}
          {checkPermission('contact', ROLE_VALUES.VIEW) ? (
            <Menu.Item 
              key="2" 
              icon={<OrderedListOutlined />}
              onClick={() => {this.props.history.push('/contacts')}}
            >
              Contacts
            </Menu.Item>
          ) : null}
          {checkPermission('user', ROLE_VALUES.VIEW) ? (
            <Menu.Item 
              key="3" 
              icon={<UserOutlined />}
              onClick={() => {this.props.history.push('/users')}}
            >
              Users
            </Menu.Item>
          ) : null}
        </Menu>
      </Sider>
      );
  }
}

export default withRouter(Menuslide);
