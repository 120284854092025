import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Select } from "antd"
import * as classes from '../Table.module.css';

import Pagination from "react-js-pagination";
import { isEmpty } from "lodash";
import Loader from "../../components/Loader/Loader";
import PermissionDeniedModal from "../../components/PermissionDeniedModal/PermissionDeniedModal";
import TextModal from "../../components/TextModal/TextModal";
import contactAPI from "../../api/ContactAPI/contactAPI";
import Layout from "../../components/Layout";
import { Input, Button } from "antd";
import {
  SearchOutlined,
} from '@ant-design/icons';

const { Option } = Select;

const Contacts = (props) => {
  const [contactData, setContactData] = useState({
    contacts: [],
    paging: null
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isShowPermissionDeniedModal, setIsShowPermissionDeniedModal] = useState(false);
  const [isShowNoDataModal, setIsShowNoDataModal] = useState(false);
  const [filterData, setFilterData] = useState({
    status: '',
    support_center_category_id: '',
    email: ''
  });

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getContacts(1);
    getCategoriesList();
  }, []);

  const getCategoriesList = async () => {
    setIsLoading(true);
    try {
      const response = await contactAPI.getCategoriesList();
      if (response) {
        const arr = [
          { id: '', label: '', name: 'Tất cả' },
        ]
        response.forEach((item) => {
          const obj = {
            id: item.id,
            label: item.name_en,
            name: item.name_vi
          }
          arr.push(obj)
        })
        setCategories([...arr])
      }
    } catch (error) {
      console.log("Faile to fetch", error);
    }
    setIsLoading(false);
  }

  const getContacts = async (page) => {
    setIsLoading(true);
    try {
      const response = await contactAPI.getContacts(page, '', '', '');
      if (response) {
        setContactData({
          contacts: response.support_center_other_questions,
          paging: response.paging
        })
      }
    } catch (error) {
      setContactData({
        contacts: [],
        paging: null
      });
      setIsShowNoDataModal(true);
      console.log("Faile to fetch", error);
    }
    setIsLoading(false);
  };

  const _handleUpdateProcessContact = async (id, step) => {
    setIsLoading(true);
    try {
      const response = await contactAPI.updateProcessContact(id, step)
      if (response) {
        const updatedContactData = { ...contactData };
        updatedContactData.contacts = updatedContactData.contacts.map(contact => (contact.id === id ? { ...contact, status: step } : contact));
        setContactData(updatedContactData);
      }
    } catch (error) {
      console.log("Faile to fetch", error);
    }
    setIsLoading(false);
  }

  const getColorProcess = (step) => {
    switch (step) {
      case 'done':
        return '#52c41a';
      case 'processing':
        return '#1890ff';
      default:
        return '#d4380d'
    }
  };

  const _handleFilterDataChange = (updatedValue) => {
    setFilterData({
      ...filterData,
      ...updatedValue
    })
  }

  const _handleSubmitFilter = () => {
    if (filterData.status !== '' || filterData.support_center_category_id !== '' || filterData.email) {
      fetchDataFilter(1);
    } else {
      getContacts(1);
    }
  }

  const fetchDataFilter = async (page) => {
    setIsLoading(true);
    try {
      const response = await contactAPI.getContacts(page, filterData.status, filterData.email, filterData.support_center_category_id);
      if (response) {
        setContactData({
          contacts: response.support_center_other_questions,
          paging: response.paging
        })
      }
    } catch (error) {
      setContactData({
        contacts: [],
        paging: null
      });
      setIsShowNoDataModal(true);
      console.log("Faile to fetch", error);
    }
    setIsLoading(false);
  }

  return (
    <Layout>
      {
        categories.length > 0 &&
        <div className="filter-group">
          <Select
            allowClear
            className={classes.paymentMethod}
            style={{ width: 200 }}
            placeholder="Trạng thái xử lý"
            value={filterData.status || null}
            onChange={(selectedOrderStatus) => _handleFilterDataChange({ status: (selectedOrderStatus || '') })}
          >
            <Option value="">Tất cả</Option>
            <Option value="waiting">Chưa xử lý</Option>
            <Option value="processing">Đang xử lý</Option>
            <Option value="done">Đã xử lý</Option>
          </Select>

          <Select
            allowClear
            className={classes.paymentMethod}
            style={{ width: 200 }}
            placeholder="Danh mục"
            value={filterData.support_center_category_id || null}
            onChange={(selectedOrderType) => _handleFilterDataChange({ support_center_category_id: (selectedOrderType || '') })}
          >
            {
              categories.map((item, index) => {
                return (
                  <Option key={`category_name_${index}`} value={item.id}>{item.name}</Option>
                )
              })
            }
          </Select>

          <Input
            placeholder="Email"
            value={filterData.email || ''}
            style={{ width: 200, marginLeft: 20 }}
            onChange={(e) => _handleFilterDataChange({ email: e.target.value })}
          />
          <Button
            className={classes.search}
            type="primary"
            icon={<SearchOutlined />}
            onClick={_handleSubmitFilter}
          >
            Tìm kiếm
          </Button>
        </div>
      }

      <table className="table table-hover">
        <tbody>
          <tr className={`${classes.backgroundTr} row`}>
            <th scope="col" className={`${classes.backgroundTh} col-2`}>Danh mục</th>
            <th scope="col" className={`${classes.backgroundTh} col-2`}>Email liên hệ</th>
            <th scope="col" className={`${classes.backgroundTh} col-2`}>Thời gian góp ý</th>
            <th scope="col" className={`${classes.backgroundTh} col-2`}>Nội dung</th>
            <th scope="col" className={`${classes.backgroundTh} col-2`}>Số điện thoại</th>
            <th scope="col" className={`${classes.backgroundTh} col-2`}>Trạng thái xử lý</th>
          </tr>
          {contactData.contacts.map((contact) => (
            <tr className="row" key={contact.id}>
              <td className={`${classes.backgroundTd} col-2`}>
                <span> {contact.support_center_category_name_vi} </span>
              </td>
              <td className={`${classes.backgroundTd} col-2`}>
                <span> {contact.email}</span>
              </td>
              <td className={`${classes.backgroundTd} col-2`}>
                <span> {contact.created_at} </span>
              </td>
              <td className={`${classes.backgroundTd} col-2`}>
                <span> {contact.description} </span>
              </td>
              <td className={`${classes.backgroundTd} col-2`}>
                <span> {contact.phone} </span>
              </td>
              <td className={`${classes.backgroundTd} col-2`}>
                <Select
                  style={{ width: 120, color: `${getColorProcess(contact.status)}` }}
                  value={contact.status}
                  onChange={(e) => _handleUpdateProcessContact(contact.id, e)}>
                  <Option value="waiting">Chưa xử lý</Option>
                  <Option value="processing">Đang xử lý</Option>
                  <Option value="done">Đã xử lý</Option>
                </Select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={classes.pagination}>
        {!isEmpty(contactData.paging) && contactData.paging.total_item >= 30 ?
          <Pagination
            hideFirstLastPages
            pageRangeDisplayed={7}
            activePage={contactData.paging.current_page}
            itemsCountPerPage={30}
            totalItemsCount={contactData.paging.total_item}
            onChange={(page) => {
              if ((filterData.status !== '' || filterData.support_center_category_name_en !== '' || filterData.email)) {
                fetchDataFilter(page);
              } else {
                getContacts(page)
              }
              window.scrollTo(0, 0)
            }}
            itemClass="page-item"
            linkClass="page-link"
            className="float-right"
          />
          : null}
      </div>
      <Loader isOpen={isLoading} />
      <PermissionDeniedModal
        isOpen={isShowPermissionDeniedModal}
        toggle={() => setIsShowPermissionDeniedModal(!isShowPermissionDeniedModal)}
      />
      <TextModal
        isOpen={isShowNoDataModal}
        toggle={() => setIsShowNoDataModal(!isShowNoDataModal)}
        bodyText={'No Data'}
      />
    </Layout>
  );
}
export default withRouter(Contacts);
