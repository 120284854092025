import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import * as classes from '../OrderDetail.module.css';
import {
  HistoryOutlined,
  ArrowRightOutlined,
  SendOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import moment from "moment";
import { currencyFormat, getOrderStatusString, paginate, getPaymentMethodToString } from "../../../../util/helpers";
import { getCurrentUser } from "../../../../util/auth";
import Pagination from "react-js-pagination";
import { get } from "lodash";
import IconArrowRight from '../../../../images/icon-arrow-right.svg';

const DEFAULT_LOGNOTES_PAGE_SIZE = 4;

const LogNotesModal = ({ orderDetail, isOpen, onCancel, _handleUpdateLogNote }) => {
  const [isShowLogNoteInput, setIsShowLogNoteInput] = useState(false);
  const [logNoteData, setLogNoteData] = useState('');
  const [historyPageNumber, setHistoryPageNumber] = useState(1);

  const user = getCurrentUser();
  const _handleSubmitLogNote = async () => {
    const response = await _handleUpdateLogNote(logNoteData);
    if (response) {
      setIsShowLogNoteInput(false);
      setLogNoteData('');
    }
  }

  return (
    <Modal
      title="Lịch sử thao tác"
      visible={isOpen}
      footer={null}
      closable={true}
      mask={false}
      maskClosable={true}
      onCancel={onCancel}
      width={700}
      centered
    >
      <table className="table">
        <tbody>
          <tr className={classes.backgroundTr}>
            <th
              scope="col"
              colSpan={2}
              className={classes.backgroundTh}
              style={{ position: 'relative' }}

            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <HistoryOutlined style={{ marginRight: 8 }} /> Lịch sử thao tác
              </div>
              <PlusCircleOutlined
                className={classes.logNoteIcon}
                style={{ color: 'green' }}
                onClick={() => setIsShowLogNoteInput(true)}
              />
            </th>
          </tr>

          {isShowLogNoteInput ? (
            <tr>
              <td className={classes.backgroundTd} >
                <div style={{ fontWeight: '600' }}>{user.result.user_name}</div>
              </td>
              <td>
                <div>
                  <input
                    className={classes.editInput}
                    style={{ margin: 0 }}
                    placeholder="Nội dung ghi chú"
                    type="text"
                    value={logNoteData}
                    onChange={(e) => setLogNoteData(e.target.value)}
                  />
                </div>
                <div
                  className={classes.logNoteContainer}
                  onClick={() => _handleSubmitLogNote(logNoteData)}
                >
                  <SendOutlined style={{ color: '#ffffff', fontSize: 12 }} />
                </div>
              </td>
            </tr>
          ) : null}
          {(orderDetail.history.length === 0 && !isShowLogNoteInput) ? (
            <tr style={{ textAlign: 'center' }}>
              <td>Không có thao tác chỉnh sửa</td>
            </tr>
          ) : null}
          {paginate(orderDetail.history, historyPageNumber, DEFAULT_LOGNOTES_PAGE_SIZE).map((historyData, index) => {
            const keys = Object.keys(historyData.data);
            if (keys.length > 0) {
              return (
                <tr key={index}>
                  <td className={classes.backgroundTd} >
                    <div style={{ fontWeight: '600' }}>{historyData.username}</div>
                    <div>{moment(historyData.created_at).format('HH:mm:ss DD/MM/YYYY')}</div>
                  </td>
  
                  <td className={classes.backgroundTd} >
                    <div style={{ fontWeight: 'bold', color: '#1890ff' }}>{historyData.message}</div>
  
                    <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                      <div style={{width: '50%'}}>{(historyData.data[keys[0]].from ? historyData.data[keys[0]].from : 'N/A')}</div>
  
                      <div style={{width: '50%', display: 'flex', alignItems: 'center', fontSize: '13px', color: '#212529'}}>
                        <img src={IconArrowRight} style={{marginRight: '4px'}} />
                        <span>{(historyData.data[keys[0]].to ? historyData.data[keys[0]].to : 'N/A')}</span>
                      </div>
                    </div>
                    {/* {historyData.change_data?.buyer_data &&
                      Object.keys(historyData.change_data?.buyer_data.from).map((key) => {
                        const isChange = historyData.change_data?.buyer_data.from[key] !== historyData.change_data?.buyer_data.to[key];
                        return isChange && (
                          <div key={key}>
                            <div style={{ fontWeight: 'bold' }}>{key}:</div>
                            {historyData.change_data?.buyer_data.from[key]} <ArrowRightOutlined style={{ verticalAlign: 'middle', fontSize: '70%' }} /> {historyData.change_data?.buyer_data.to[key]}
                          </div>
                        )
                      })
                    }
                    {historyData.change_data?.resend_email_status}
                    {historyData.change_data?.resend_email_to_participant_status}
                    {historyData.change_data?.log_note_message}
    
                    {historyData.change_data?.participant_data && (
                      <>
                        <span style={{ fontWeight: '600' }}>
                          {historyData.change_data?.participant_data.from.first_name}{' '}
                          {historyData.change_data?.participant_data.from.last_name} <small>(attendant)</small>
                        </span>
                        {Object.keys(historyData.change_data?.participant_data.from).map((key) => {
                          const isChange = historyData.change_data?.participant_data.from[key] !== historyData.change_data?.participant_data.to[key];
                          return isChange && (
                            <div key={key}>
                              <div style={{ fontWeight: 'bold' }}>{key}:</div>
                              {historyData.change_data?.participant_data.from[key]} <ArrowRightOutlined style={{ verticalAlign: 'middle', fontSize: '70%' }} /> {historyData.change_data?.participant_data.to[key]}
                            </div>
                          )
                        })}
                      </>
                    )}
    
                    {historyData.change_data?.tax_info &&
                      Object.keys(historyData.change_data?.tax_info.from).map((key) => {
                        const isChange = historyData.change_data?.tax_info.from[key] !== historyData.change_data?.tax_info.to[key];
                        return isChange && (
                          <div key={key}>
                            <div style={{ fontWeight: 'bold' }}>{key}:</div>
                            {historyData.change_data?.tax_info.from[key]} <ArrowRightOutlined style={{ verticalAlign: 'middle', fontSize: '70%' }} /> {historyData.change_data?.tax_info.to[key]}
                          </div>
                        )
                      })
                    }
    
                    {historyData.change_data?.payment_status &&
                      <div>
                        {getOrderStatusString(parseInt(historyData.change_data?.payment_status.from))} <ArrowRightOutlined style={{ verticalAlign: 'middle', fontSize: '70%' }} /> {getOrderStatusString(parseInt(historyData.change_data?.payment_status.to))}
                      </div>
                    }
                    {historyData.change_data?.request_refund ? (
                      <>
                        <div>
                          <span
                            style={{
                              color: historyData.change_data?.request_refund.status === 'Success' ? 'rgb(82, 196, 26)' : 'rgb(248, 0, 34)',
                              fontWeight: '600'
                            }}
                          >
                            {historyData.change_data?.request_refund.status}
                          </span>
                        </div>
                        {historyData.change_data?.request_refund.status === 'Success' ? (
                          <div>
                            Yêu cầu hoàn tiền: {" "}
                            <strong style={{ fontWeight: '600' }}>
                              {currencyFormat(+get(historyData.change_data.request_refund, 'request_refund_data.refund_amount', 0))}
                            </strong>
                            <br />
                            Lý do hoàn tiền: {" "}
                            <strong style={{ fontWeight: '600' }}>
                              {get(historyData.change_data.request_refund, 'request_refund_data.refund_reason', '')}
                            </strong>
                            <div>Trạng thái đơn hàng:</div>
                            <span style={{ fontWeight: '600' }}>
                              {getOrderStatusString(+historyData.change_data.request_refund.from)}
                              <ArrowRightOutlined style={{ verticalAlign: 'middle', fontSize: '70%', margin: '0 4px' }} />
                              {getOrderStatusString(+historyData.change_data.request_refund.to)}
                            </span>
    
                          </div>
                        ) : (
                          <div>
                            {get(historyData.change_data.request_refund, 'request_refund_data.message', '')}
                          </div>
                        )}
                      </>
                    ) : null}
    
                    {historyData.change_data?.payment_method &&
                      <div>
                        {getPaymentMethodToString(historyData.change_data?.payment_method.from)} <ArrowRightOutlined style={{ verticalAlign: 'middle', fontSize: '70%' }} /> {getPaymentMethodToString(historyData.change_data?.payment_method.to)}
                      </div>
                    }
    
                    {historyData.change_data?.refund_notification_data ? (
                      <>
                        <div>
                          <span
                            style={{
                              color: historyData.change_data?.refund_notification_data.status === 'Success' ? 'rgb(82, 196, 26)' : 'rgb(248, 0, 34)',
                              fontWeight: '600'
                            }}
                          >
                            {historyData.change_data?.refund_notification_data.status}
                          </span>
                        </div>
                        {historyData.change_data?.refund_notification_data.status === 'Success' ? (
                          <div>
                            Yêu cầu hoàn tiền: {" "}
                            <strong style={{ fontWeight: '600' }}>
                              {currencyFormat(+get(historyData.change_data.refund_notification_data, 'refund_notification_data.request_refund_status', 0))}
                            </strong>
                            <br />
                            Lý do hoàn tiền: {" "}
                            <strong style={{ fontWeight: '600' }}>
                              {get(historyData.change_data.refund_notification_data, 'refund_notification_data.refund_reason', '')}
                            </strong>
                            <br />
                            Số tiền đã hoàn cho người mua: {" "}
                            <strong style={{ fontWeight: '600' }}>
                              {currencyFormat(+get(historyData.change_data.refund_notification_data, 'refund_notification_data.total_refund_to_buyer', 0))}
                            </strong>
                          </div>
                        ) : null}
                        <div>Trạng thái đơn hàng:</div>
                        <span style={{ fontWeight: '600' }}>
                          {getOrderStatusString(+historyData.change_data.refund_notification_data.from)}
                          <ArrowRightOutlined style={{ verticalAlign: 'middle', fontSize: '70%', margin: '0 4px' }} />
                          {getOrderStatusString(+historyData.change_data.refund_notification_data.to)}
                        </span>
                      </>
                    ) : null} */}
  
                  </td>
                </tr>
              )
            }
            
          })}
        </tbody>
      </table>
      {orderDetail.history.length >= DEFAULT_LOGNOTES_PAGE_SIZE + 1 && (
        <Pagination
          hideFirstLastPages
          pageRangeDisplayed={7}
          activePage={historyPageNumber}
          itemsCountPerPage={DEFAULT_LOGNOTES_PAGE_SIZE}
          totalItemsCount={orderDetail.history.length}
          onChange={(pageNumber) => setHistoryPageNumber(pageNumber)}
          itemClass="page-item"
          linkClass="page-link"
          style={{
            padding: 20,
            textAlign: 'right',
            width: '100%',
          }}
        />
      )}
    </Modal>
  )
}

export default LogNotesModal;