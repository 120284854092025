import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { toast } from "react-toastify";
import { get, isEmpty } from "lodash";
import userAPI from "../../../api/UserAPI/userAPI";
import Layout from "../../../components/Layout";
import { Select } from 'antd';
import { ROLE_VALUES } from "../../../util/constants";

const { Option } = Select;

const contactRoleOptions = [
  // { value: ROLE_VALUES.VIEW, label: "View" },
];
const userRoleOptions = [
  // { value: "change_status", label: "Change Status" },
  // { value: "reset_pw", label: "Reset Password" },
  // { value: ROLE_VALUES.VIEW, label: "View" },
  // { value: "add-edit", label: "Add/Edit User" }
];
const orderRoleOptions = [
  { value: ROLE_VALUES.VIEW, label: "View" },
  { value: ROLE_VALUES.RESEND_PAYMENT_EMAIL, label: "Resend Payment Email" },
  { value: ROLE_VALUES.EDIT_CUSTOMER_INFO, label: "Edit Customer Info" },
  { value: ROLE_VALUES.REFUND, label: "Refund" },
  { value: ROLE_VALUES.VAT_ACTION, label: "Add/Edit VAT Info" },
  { value: ROLE_VALUES.EDIT_PARTICIPANT_INFO, label: "Edit Participant Info" },
  { value: ROLE_VALUES.EXPORT_ACTIONS, label: "Export Order & Order Items" },
  { value: ROLE_VALUES.CHANGE_STATUS, label: "Change Order Status" },
  { value: ROLE_VALUES.RESEND_QR_CODE_EMAIL, label: "Resend QR Code Email" }
];


const USER_PERMISSION_FIELDS = [
  {
    key: 'user',
    label: 'User',
    options: userRoleOptions
  },
  {
    key: 'contact',
    label: 'Contact',
    options: contactRoleOptions
  },
  {
    key: 'list',
    label: 'Orders',
    options: orderRoleOptions
  }
];

const USER_INFO_FIELDS = [
  {
    key: 'user_name',
    label: 'User name',
  },
  {
    key: 'full_name',
    label: 'Full name'
  },
  {
    key: 'password',
    label: 'Password'
  }
];

const CreateUser = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    full_name: '',
    user_name: '',
    password: '',
  });

  const [userRoles, setUserRoles] = useState([  
    {object: "contact", action: ""},
    {object: "list", action: ""},
    {object: "user", action: ""}
  ])

  const _handleCreacteUser = async () => {
    if (isEmpty(userInfo['user_name']) || isEmpty(userInfo['password']) || isEmpty(userInfo['full_name'])) {
      return toast.error('Please fill in required fields', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
    }
    setIsLoading(true);
    try {
      const params = {
        type: "account",
        full_name: userInfo['full_name'],
        user_name: userInfo['user_name'],
        password: userInfo['password'],
        roles: userRoles,
      };

      const response = await userAPI.createUser(params);

      if (response) {
        props.history.push("/users");
        toast.success('User Created Successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const _handleUserInfoChange = (key, value) => {
    const updatedUserInfo = {...userInfo};
    updatedUserInfo[key] = value;
    setUserInfo(updatedUserInfo);
  }

  const _handleSelectRole = ({key, value}) => {
    const updatedUserRoles = [...userRoles]
    const selectedRoleIndex = updatedUserRoles.findIndex(role => role.object === key);

    if (selectedRoleIndex < 0) return;

    updatedUserRoles[selectedRoleIndex].action = isEmpty(value) ? "" : (value.includes('*') ? '*' : value.join(','));
    setUserRoles(updatedUserRoles);
  };

  return (
    <Layout>
        <div className="page-wrapper">
          <div className="main-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-box">
                    <div className="main-box-header">
                      <h3 className="title-5 m-b-35">Create User</h3>
                    </div>
                    <div className="error-alert" id="error-alert"></div>
                    <div
                      className="main-box-body"
                      style={{ background: "white" }}
                    >
                      <div
                        className="row"
                        style={{ margin: 0, padding: 20 }}
                      >
                        {USER_INFO_FIELDS.map((field, index) => (
                          <div className="col-md-6" key={index}>
                            <div className="form-group">
                              <p
                                className="form-control-s2"
                                style={{
                                  fontSize: "1rem",
                                  marginBottom: "8px",
                                }}
                              >
                                {field.label}:
                                <span
                                  className="text-danger"
                                  style={{ color: "red" }}
                                >
                                  &nbsp;*
                                </span>
                              </p>
                              <input
                                id={field.key}
                                name={field.key}
                                className="form-control"
                                value={userInfo[field.key]}
                                onChange={(e) => _handleUserInfoChange(field.key, e.target.value)}
                              />
                            </div>
                          </div>
                        ))}
                        <div className="col-md-12 pt-5">
                          <div className="form-group">
                            <p
                              className="form-control-s2 pb-2"
                              style={{
                                fontSize: "1rem",
                                marginBottom: "8px",
                              }}
                            >
                              User Permission:
                              <span
                                className="text-danger"
                                style={{ color: "red" }}
                              >
                                &nbsp;*
                              </span>
                            </p>
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{
                                      width: "45%",
                                      fontSize: ".9rem",
                                    }}
                                  >
                                    OBJECT
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      width: "45%",
                                      fontSize: ".9rem",
                                    }}
                                  >
                                    ACTION
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {USER_PERMISSION_FIELDS.map((field, index) => (
                                  <tr key={index}>
                                    <td className="align-middle">
                                      <span style={{ fontSize: "1rem" }}>
                                        {field.label}
                                      </span>
                                    </td>
                                    <td className="px-0">
                                      <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        value={isEmpty(userRoles.find(role => role.object === field.key).action) ? [] : userRoles.find(role => role.object === field.key).action.split(',')}
                                        onChange={(selected) => _handleSelectRole({
                                          key: field.key,
                                          value: selected
                                        })}
                                      >
                                        <Option value="*">
                                          All
                                        </Option>
                                        {!get(userRoles.find(role => role.object === field.key), 'action', []).includes("*") && field.options.map(
                                          (fieldOption) => {
                                            return (
                                              <Option
                                                key={fieldOption.value}
                                                value={fieldOption.value}
                                              >
                                                {fieldOption.label}
                                              </Option>
                                            );
                                          }
                                        )}

                                      </Select>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-md-12 pt-5 text-right">
                          <button
                            type="submit"
                            className="btn btn-primary btn-lg btn-float"
                            onClick={_handleCreacteUser}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader isOpen={isLoading} />
        </div>
    </Layout>
  );
}
export default withRouter(CreateUser);
