import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Divider, Button } from "antd"
import * as classes from './OrderDetail.module.css';
import { getCurrentUser } from "../../../util/auth";
import { toast } from 'react-toastify';

import {
  HistoryOutlined
} from '@ant-design/icons';
import { get, isEmpty } from "lodash";
import { useEffect } from "react";
import BuyerInfo from "./BuyerInfo/BuyerInfo";
import TaxInfo from "./TaxInfo/TaxInfo";
import OrderInfo from "./OrderInfo/OrderInfo";
import Loader from "../../../components/Loader/Loader";
import EventInfo from "./EventInfo/EventInfo";
import PurchasedProducts from "./PurchasedProducts/PurchasedProducts";
import LogNotesModal from "./LogNotesModal/LogNotesModal";
import { getOrderStatusString, getOrderStatus, checkPermission, getPaymentMethodToString } from "../../../util/helpers";
import Attendants from "./Attendants/Attendants";
import orderAPI from "../../../api/OrderAPI/orderAPI";
import Layout from "../../../components/Layout";
import TextModal from "../../../components/TextModal/TextModal";
import TaxModal from "./TaxInfo/TaxModal/TaxModal";
import { ROLE_VALUES } from "../../../util/constants";

const OrderDetail = (props) => {
  const code = props.match.params.code;
  const user = getCurrentUser();
  const [orderDetail, setOrderDetail] = useState();
  const [usedCode, setUsedCode] = useState('');
  
  const [isLoading, setIsLoading] = useState(false);
  const [isShowNoDataModal, setIsShowNoDataModal] = useState(false);
  const [isShowLogNotesModal, setIsShowLogNotesModal] = useState(false);
  const [isShowTaxModal, setIsShowTaxModal] = useState(false);

  useEffect(() => {
    if (code) {
      getOrderDetail(code);
    }
  }, [code])

  const getOrderDetail = async (code) => {
    setIsLoading(true);
    try {
      const response = await orderAPI.getOrderDetail(code);
      setOrderDetail(response);
      if (response.coupons && response.coupons.length > 0) {
        setUsedCode(response.coupons[0])
      }
    } catch (error) {
      setIsShowNoDataModal(true);
      console.log("Faile to fetch", error);
    }
    setIsLoading(false);
  };

  const _handOrderStatusChange = async (value) => {
    let paymentStatus = getOrderStatus(value)
    const params = {
      payment_status: paymentStatus,
      transaction_id: orderDetail.transactions[0].id,
      username: user.result.user_name
    };
    
    if (window.confirm("Bạn chắc chắn muốn chuyển trạng thái đơn hàng sang " + getOrderStatusString(paymentStatus).toUpperCase() + "?")) {
      setIsLoading(true);
      try {
        const response = await orderAPI.updateOrderStatus(orderDetail.code, params)
        if (response) {
          getOrderDetail(orderDetail.code);
          toast.success('Order Status Updated Successfully!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          })
        }          
      } catch (error) {
        console.log("Faile to fetch", error);
      }
      setIsLoading(false);
    }
  }

  const _handleChangePaymentMethod = async (value) => {
    // let paymentStatus = getOrderStatus(value)
    const params = {
      payment_method: value,
      transaction_id: orderDetail.transactions[0].id,
      username: user.result.user_name
    };
    
    if (window.confirm("Bạn chắc chắn muốn chuyển hình thức thanh toán sang " + getPaymentMethodToString(value).toUpperCase() + "?")) {
      setIsLoading(true);
      try {
        const response = await orderAPI.updatePaymentStatus(orderDetail.code, params)
        if (response) {
          getOrderDetail(orderDetail.code);
          toast.success('Payment Method Updated Successfully!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          })
        }          
      } catch (error) {
        console.log("Faile to fetch", error);
      }
      setIsLoading(false);
    }
  }

  const _createTaxInfo = async (data) => {
    try {        
      const submitData = {
        username: user.result.user_name,
        tax: {...data}
      };

      const response = await orderAPI.createTax(orderDetail.code, submitData)
      if (response) {
        getOrderDetail(code);
        setIsShowTaxModal(false);
        toast.success('Tax Info Created Successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
      return response;
    } catch (e) {
      const errorMessage = e.response.data.error?.message || 'Đã có lỗi xảy ra'
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
      console.log(e)
    }
  }

  const _updateTaxInfo = async (data) => {
    try {        
      const submitData = {
        username: user.result.user_name,
        tax: {...data}
      };

      const response = await orderAPI.updateTax(orderDetail.code, submitData)
      if (response) {
        getOrderDetail(code);
      }
      return response;
    } catch (e) {
      const errorMessage = e.response.data.error?.message || 'Đã có lỗi xảy ra'
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
      console.log(e)
    }
  }

  const _updateBuyerInfo = async () => {
    if (window.confirm("Bạn có chắc muốn đối thông tin người mua này?")) {
      setIsLoading(true);
      try {        
        const submitData = {
          username: user.result.user_name,
          buyer: {
            buyer_name: orderDetail.buyer.name,
            buyer_email: orderDetail.buyer.email,
            buyer_phone: orderDetail.buyer.phone,
            buyer_phone_area_code: orderDetail.buyer.phone_area_code
          }
        }
        const response = await orderAPI.updateBuyerInfo(orderDetail.code, submitData)
        if (response) {
          getOrderDetail(code);
        }
        return response;
      } catch (e) {
        const errorMessage = e.response.data.error?.message || 'Đã có lỗi xảy ra'
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
        console.log(e)
      }
      setIsLoading(false);
    }
  }

  const _handleUpdateLogNote = async (logNote) => {
    if (isEmpty(logNote)) return;
    setIsLoading(true);
    try {
      const submitData = {
        username: user.result.user_name,
        log_note: logNote
      }
      const response = await orderAPI.updateLogNote(orderDetail.code, submitData)
      if (response) {
        getOrderDetail(code)
        return response;
      }
    } catch (e) {
      console.log(e);
      const errorMessage = e.response.data.error?.message || 'Đã có lỗi xảy ra'
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
      console.log(e)
    }
    setIsLoading(false);
  }

  const _resendEmail = async (orderCode) => {
    if (window.confirm("Bạn có chắc chắn muốn gửi lại email thanh toán thành công cho đơn hàng này?")) {
      setIsLoading(true);
      try {
        const submitData = {
          username: user.result.user_name
        };
        const response = await orderAPI.resendPaymentSuccessEmail(orderCode, submitData);
        if (response) {
          getOrderDetail(orderCode);
          toast.success('Email Sent Successfully!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          })
        }
      } catch (error) {
        console.log("Faile to fetch", error);
      }
      setIsLoading(false);
    }
  }

  const _resendQRCodeEmail = async (orderCode) => {
    if (window.confirm("Bạn có chắc chắn muốn gửi lại email QR Code cho đơn hàng này?")) {
      setIsLoading(true);
      try {
        const submitData = {
          username: user.result.user_name
        };
        const response = await orderAPI.resendQRCodeEmail(orderCode, submitData);
        if (response) {
          getOrderDetail(orderCode);
          toast.success('Email Sent Successfully!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          })
        }
      } catch (error) {
        console.log("Faile to fetch", error);
      }
      setIsLoading(false);  
    }
  }


  const _submitRefundData = async (refundData) => {
    if (window.confirm("Bạn có chắc chắn muốn thực hiện hoàn tiền cho đơn hàng này?")) {
      setIsLoading(true);
      try {
        const submitData = {
          username: user.result.user_name,
          refund_data: refundData
        }
        const response = await orderAPI.refund(orderDetail.code, orderDetail.transactions[0].id, submitData)
        if (response) {
          getOrderDetail(code);
          toast.success('Thao tác hoàn tiền đã được thực hiện thành công!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
          return response;
        }
      } catch (e) {
        console.log(e);
        const errorMessage = e.response.data.error?.message || 'Đã có lỗi xảy ra'
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
        console.log(e)
      }
      setIsLoading(false);
      
    }
  }

  const _handleBuyerInfoFieldChange = (key, value) => {
    const updatedOrderDetail = {...orderDetail};
    updatedOrderDetail['buyer'][key] = value;
    setOrderDetail(updatedOrderDetail);
  }

  const isTaxDataEmpty = Object.values(get(orderDetail, 'tax', {})).every(x => x === null || x === '');

  return (
    <Layout>
      {!isEmpty(orderDetail) ?
      <Row>
        <Col flex={3}>
          <Row>
            <Col className={classes.orderCode} span={12}>#{orderDetail.code}</Col>
            <Col span={12} className="text-right">
              <Button 
                type="button" 
                className="btn btn-primary mr-2"
                style={{height: 40}} 
                onClick={() => setIsShowLogNotesModal(true)}>
                <HistoryOutlined />
              </Button>
              {(checkPermission('list', ROLE_VALUES.VAT_ACTION) && isTaxDataEmpty && orderDetail.order_status === 'dat_hang_thanh_cong') ? (
                <Button 
                  type="button" 
                  className="btn btn-primary mr-2"
                  style={{height: 40, color: '#1890ff', fontWeight: '600'}} 
                  onClick={() => setIsShowTaxModal(true)}
                >
                  Thêm VAT
                </Button>
              ) : null}
              {checkPermission('list', ROLE_VALUES.RESEND_QR_CODE_EMAIL) && orderDetail.order_status === 'dat_hang_thanh_cong' ?
                <Button
                  type="button"
                  className="btn btn-primary mr-2"
                  style={{height: 40, color: '#1890ff', fontWeight: '600'}} 
                  onClick={() => _resendQRCodeEmail(orderDetail.code)}
                >
                  Gửi lại email QR Code
                </Button> : null
              }

              {checkPermission('list', ROLE_VALUES.RESEND_PAYMENT_EMAIL) && orderDetail.order_status === 'dat_hang_thanh_cong' ?
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => _resendEmail(orderDetail.code)}
                >
                  Gửi lại email thanh toán thành công
                </button> : null
              }
            </Col>
          </Row>
          <BuyerInfo 
            orderDetail={orderDetail} 
            _handleBuyerInfoFieldChange={(key, value) => _handleBuyerInfoFieldChange(key, value)} 
            _updateBuyerInfo={_updateBuyerInfo}
          />
          <Divider></Divider>
          {(!isTaxDataEmpty) ? <TaxInfo orderDetail={orderDetail} _submitTaxData={(data) => _updateTaxInfo(data)} /> : null}
          <OrderInfo 
            orderDetail={orderDetail} 
            _handOrderStatusChange={(status) => _handOrderStatusChange(status)}
            _handleChangePaymentMethod={(e) => _handleChangePaymentMethod(e)}
            _submitRefundData={(data) => _submitRefundData(data)}
          />
          <Divider></Divider>
          <EventInfo orderDetail={orderDetail} />
          <Divider></Divider>
          <PurchasedProducts orderDetail={orderDetail} usedCode={usedCode} />
          <Attendants  {...props} setIsLoading={setIsLoading} />
        </Col>
        {checkPermission('list', ROLE_VALUES.VAT_ACTION) ? (
          <TaxModal 
            isOpen={isShowTaxModal} 
            onCancel={() => setIsShowTaxModal(false)}
            orderDetail={orderDetail}
            _submitTaxData={(data) => _createTaxInfo(data)}
          />
        ) :null}

        <LogNotesModal 
          isOpen={isShowLogNotesModal} 
          onCancel={() => setIsShowLogNotesModal(false)}
          orderDetail={orderDetail}
          _handleUpdateLogNote={(logNote) => _handleUpdateLogNote(logNote)}
        />
      </Row> : null}
      <Loader isOpen={isLoading} />
      <TextModal
        isOpen={isShowNoDataModal} 
        toggle={() => setIsShowNoDataModal(!isShowNoDataModal)}
        bodyText={'No Data'}
      />
    </Layout>
  );

}
export default withRouter(OrderDetail);
