import React, { useState } from "react";
import { Button, Modal } from "antd";
import { toast } from "react-toastify";
import { currencyFormat } from "../../../../util/helpers";
import { isEmpty } from "lodash";

const TOAST_ERROR_STYLE = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
}

const RefundModal = ({ isOpen, onCancel, _submitRefundData }) => {
  const [refundAmount, setRefundAmount] = useState(0);
  const [refundReason, setRefundReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const _handleSubmitRefundData = async () => {
    if (+refundAmount < 1000) {
      return toast.error(`Số tiền hoàn tối thiểu là ${currencyFormat(1000)}`, TOAST_ERROR_STYLE)
    }
    if (isEmpty(refundReason)) {
      return toast.error(`Vui lòng nhập lý do hoàn tiền`, TOAST_ERROR_STYLE)
    }
    const submitData = {
      refund_amount: refundAmount,
      refund_reason: refundReason
    };
    setIsLoading(true);
    const response = await _submitRefundData(submitData);
    setIsLoading(false);
    if (response) {
      onCancel();
      setRefundAmount(0);
      setRefundReason('');
    }
  }

  return (
    <Modal       
      title="Thông tin hoàn tiền"
      visible={isOpen}
      footer={null}
      closable={true}
      // mask={false}
      maskClosable={true}
      onCancel={onCancel}
      width={360}
      centered
    >
      <div className="form-group">
        <label htmlFor="amount">Số tiền sẽ hoàn <span style={{color: 'red'}}>*</span></label>
        <input 
          type="number" 
          className="form-control" 
          id="amount" 
          value={refundAmount} 
          onChange={(e) => setRefundAmount(e.target.value)} 
        />
        <small style={{fontSize: 10}}>Só tiền tối thiểu: {currencyFormat(1000)}</small>
      </div>
      <div className="form-group">
        <label htmlFor="reason">Lý do <span style={{color: 'red'}}>*</span></label>
        <textarea 
          className="form-control" 
          id="reason" 
          rows="3" 
          value={refundReason} 
          onChange={(e) => setRefundReason(e.target.value)}
        ></textarea>
      </div>
      <div className="w-100 text-center">
        <Button 
          type="primary"
          className="btn btn-primary"
          color=""
          loading={isLoading}
          onClick={_handleSubmitRefundData}
        >
          Hoàn tiền
        </Button>
      </div>
    </Modal>
  )
}

export default RefundModal;