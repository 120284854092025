import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import * as classes from '../Table.module.css';
import Switch from "react-switch";
import TextModal from "../../components/TextModal/TextModal";
import PermissionDeniedModal from "../../components/PermissionDeniedModal/PermissionDeniedModal";
import Loader from "../../components/Loader/Loader";
import userAPI from "../../api/UserAPI/userAPI";
import Layout from "../../components/Layout";
import { toast } from "react-toastify";

const Users = (props) => {

  const [users, setUsers] = useState([]);
  const [resetPasswordValue, setResetPasswordValue] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isShowNoDataModal, setIsShowNoDataModal] = useState(false);
  const [isShowPermissionModal, setIsShowPermissionModal] = useState(false);
  const [isShowPasswordModal, setIsShowPasswordModal] = useState(false);

  useEffect(() => {
    getUsers();
  }, [])

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const response = await userAPI.getUsers();
      if (response) {
        setUsers(response);
      }
    } catch (error) {
      setIsShowNoDataModal(true);
      console.log("Faile to fetch", error);
    }
    setIsLoading(false);
  };

  const _handleActivationChange = async (checked, e, index) => {
    const isActive = +checked;

    let updatedUsers = [...users];
    if (!updatedUsers[index]) return;
    try {
      const response = await userAPI.activation(isActive, updatedUsers[index].id);

      if (response) {
        console.log(response);
        updatedUsers[index].is_active = isActive;
        setUsers(updatedUsers);
      }
    } catch (error) {
      const errorMessage = error.response.data.error?.message || 'Đã có lỗi xảy ra'
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      console.log("Faile to fetch", error);
    }
  };

  const _handleResetPassword = async (id) => {
    if (window.confirm('Bạn có chắc chắn muốn reset mật khẩu cho người dùng này ?')) {
      try {
        const response = await userAPI.resetPassword(id);
        if (response) {
          setResetPasswordValue(response.password);
          setIsShowPasswordModal(true);
        }    
      } catch (error) {
        const errorMessage = error.response.data.error?.message || 'Đã có lỗi xảy ra'
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setResetPasswordValue('');
        console.log("Faile to fetch", error);
      }
    }
  };

  const _navigateEditPage = (id) => {
    props.history.push(`/users/${id}`);
  };

  const _navigateCreatePage = () => {
    props.history.push("/users/create");
  };

  return (
    <Layout>
      <div style={{paddingBottom: '10px'}}>
        <span 
          style={{fontSize: '30px', fontWeight: 'bold'}}
          >
          User admin
        </span>
        <button
          onClick={_navigateCreatePage}
          style={{float: 'right'}}
          type="button"
          className="btn btn-primary"
          >
          Create user
        </button>
      </div>
      <table className="table table-hover">
        <tbody>
          <tr className={classes.backgroundTr}>
            <th scope="col" className={classes.backgroundTh}>#</th>
            <th scope="col" className={classes.backgroundTh}>Avatar</th>
            <th scope="col" className={classes.backgroundTh}>Username</th>
            <th scope="col" className={classes.backgroundTh}>Full name</th>
            <th scope="col" className={classes.backgroundTh}>Active</th>
            <th scope="col" className={classes.backgroundTh}>Password</th>
          </tr>
        {users.map((user, index) =>  {
          let position = index + 1;
          return (
          <tr key={user.id}>
            <td className={classes.backgroundTd}>
              <span> {position} </span>
            </td>
            <td className="image" >
              {" "}
              <img className="rounded-circle" style={{ width: 50, height: 50, }} src={user.avatar} alt=" " />{" "}              
            </td>
            <td 
              className={classes.backgroundTd} 
              style={{color: '#1890ff', cursor: 'pointer'}}
              onClick={() => _navigateEditPage(user.id)}
            >
              <span> {user.user_name} </span>
            </td>
            <td className={classes.backgroundTd} style={{color: '#52c41a'}}>
              <span> {user.full_name} </span>
            </td>
            <td>
              <Switch
                id={index.toString()}
                itemID={index.toString()}
                onChange={_handleActivationChange}
                checked={(+user.is_active === 1) ? true : false}>
              </Switch>
            </td>
            <td>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => _handleResetPassword(user.id)}>
                Reset
              </button>
            </td>
          </tr>
          );
        })}
        </tbody>
      </table>
      <Loader isOpen={isLoading} />
      <TextModal 
        isOpen={isShowNoDataModal} 
        toggle={() => setIsShowNoDataModal(!isShowNoDataModal)}
        bodyText={'No Data'}
      />
      <TextModal 
        isOpen={isShowPasswordModal} 
        toggle={() => setIsShowPasswordModal(!isShowPasswordModal)}
        headerText={'Password'}
        bodyText={resetPasswordValue}
      />
      <PermissionDeniedModal 
        isOpen={isShowPermissionModal} 
        toggle={() => setIsShowPermissionModal(!isShowPermissionModal)}
      />
    </Layout>
  );
}
export default withRouter(Users);
