import React, {Fragment, useState } from "react";
import MenuSlide from "./MenuSlide";
import {
  withRouter,
  Link,
} from "react-router-dom";

import 'antd/dist/antd.css'; 
import { Layout } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import { getCurrentUser, logout } from "../util/auth";

const { Header, Content } = Layout;

const MainLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false)

  const toggle = () => {
    setCollapsed(!collapsed)
  };
  
  const handleLogout = () => {
    logout();
  };

  const user = getCurrentUser();

  return (
    <Fragment>
      <Layout style={{minHeight:'100vh'}}>
        <MenuSlide toggledCollapse = {collapsed} />
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
          {Object.keys(user).length !== 0 ? 
            <div style={{float: 'right', maxHeight: '100%', position: 'relative'}}>
              <ul className="navbar-nav" style={{flex: 1, marginRight: '16px'}}>
                <li className="nav-item dropdown">
                  <a 
                    className="nav-link dropdown-toggle" 
                    href="/#" id="navbarDropdownMenuLink" 
                    role="button" data-toggle="dropdown" 
                    aria-haspopup="true" 
                    aria-expanded="false"
                    style={{maxWidth: '100%'}}>
                    <img src={user.result.avatar} style={{maxWidth: '40px', height: 'auto', marginRight: '12px'}} className="rounded-circle" alt=""/>
                      {user.result.full_name}
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    {/* <a className="dropdown-item" style={{lineHeight: 1.5}} href="#">Edit Profile</a> */}
                    <Link to={'/profile/password'} className="dropdown-item" style={{lineHeight: 1.5}}>Change Password</Link>
                    <a onClick={handleLogout} className="dropdown-item" style={{lineHeight: 1.5}} href="/#">Log Out</a>
                  </div>
                </li>   
              </ul>
            </div> : null}
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: toggle,
            })}
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Fragment>
  );
}

export default withRouter(MainLayout);
