import React from "react";
import { Col, Row } from "antd";
import * as classes from '../OrderDetail.module.css';

const EventInfo = ({ orderDetail }) => {
  return (
    <Row gutter={[16, 16]} className={classes.infoContainer}>
      <Col className={classes.label} span={24}>Thông tin sự kiện</Col>
      <Col span={12}>
        Tên sự kiện :
        <span className={classes.buyerLabel}> {orderDetail.event.name_vi}  </span>
      </Col>
      <Col span={12}>
        Đơn vị tổ chức :
        <span className={classes.buyerLabel}> {orderDetail.merchant.name_vi} </span>
      </Col>
    </Row>
  )
}

export default EventInfo