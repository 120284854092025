import React from 'react';
import PacmanLoader from "react-spinners/PacmanLoader";
import LoadingOverlay from "react-loading-overlay";

const Loader = ({ isOpen }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        margin: "0 auto",
        opacity: 0.5,
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        visibility: isOpen ? 'visible' : 'hidden'
      }}
    >
      <div style={{ position: "absolute", top: "50%", left: "40%" }}>
        <LoadingOverlay
          active={isOpen}
          spinner={<PacmanLoader color="#1794fc" size="30px" />}
        ></LoadingOverlay>
      </div>
    </div>
  )
}

export default Loader;