import { isEmpty } from 'lodash';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const TextModal = ({ isOpen, toggle, headerText = '', bodyText = '' }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      {!isEmpty(headerText) ? <ModalHeader toggle={toggle}>Password</ModalHeader>: null}
      <ModalBody className="text-center">
        {bodyText}
      </ModalBody>
    </Modal>
  )
}

export default TextModal;