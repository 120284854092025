// api/axiosClient.js
import axios from "axios";
import queryString from "query-string";
import { getToken } from "../util/auth";

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#requestconfig` for the full list of configs
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://api.uat-cs.actiup.net/api',
  headers: {
    "content-type": "application/json",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  // Handle token here ...
  config.headers["access-token"] = getToken();
  config.headers["Authorization"] = (config["baseURL"] === "https://api.uat-cs.actiup.net/api") ? `Basic MmI5ZGE4MjAtYmM0Yy00MzMyLTk1NTItMzQ0ZTI4MjY3M2RmOjZlODRhNGU0MjMyY2ZmZmUxZDg0Yzg5ZTc0ZDA1NDA2` : `Basic N3M2aGE4MjAtYmM0Yy0xMmdiLTk1NTItMzQ0ZTI4MjgyN3NuOjZlODRhNGU0MjMyY2ZmZmUxZDg0Yzg5ZTc0ZDA1NDA2
  `;
  return config;
});
axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    // Handle errors
    throw error;
  }
);

export default axiosClient;
