import React, { useState, Fragment } from "react";
import logo from "../../images/actiup.png";
import { withRouter } from "react-router-dom";
import { setUser, setToken } from "../../util/auth";
import './login.css';
import Loader from "../../components/Loader/Loader";
import userAPI from "../../api/UserAPI/userAPI";
import moment from "moment";

const Login = (props) => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const _handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const params = {
        user_name: username,
        password: password,
      };
      const response = await userAPI.login(params);
      if (response) {
        setUser(response.user);
        setToken(response.token);
        const currentTime = moment();
        const exTime = moment().add(60, 'minutes');
        localStorage.setItem('time', currentTime);
        localStorage.setItem('expiredTime', exTime);
        window.location.href="/orders";
      }
    } catch (error) {
      error["error"] = "The user_name or password is incorrect / You don't have permission";
      setErrors(error);
    }
    setIsLoading(false);
  };

  return (
    <Fragment>
      <div className="login-page">
        <div className="form" style={{borderRadius: '3px'}}>
          <img style={{maxWidth: '100%', height: '75px',}} src={logo} alt=""></img>
          <form className="login-form" style={{marginTop: 36}}>
            <input 
              style={{borderRadius: '3px', height: '40px'}} 
              type="text" 
              placeholder="User name" 
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input 
              style={{borderRadius: '3px', height: '40px', marginBottom: '2px'}} 
              type="password" 
              placeholder="Password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="text-danger" style={{ color: "red"}}>
              {errors["error"]}
            </span>
            <button 
              style={{borderRadius: '3px', height: '40px', lineHeight: '0.25', marginTop: "12px"}}
              onClick={_handleLogin}
            >Login</button>
          </form>
        </div>
      </div>
      <Loader isOpen={isLoading} />
    </Fragment>
  );
  
}

export default withRouter(Login);
