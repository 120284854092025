export const ATTENDANTS_EDIT_FIELDS = [
  {
    key: 'first_name',
    label: 'First name',
    type: 'text'
  },
  {
    key: 'last_name',
    label: 'Last name',
    type: 'text'
  },
  {
    key: 'email',
    label: 'Email',
    type: 'text'
  },
  {
    key: 'phone',
    label: 'Số điện thoại',
    type: 'text'
  },
  {
    key: 'dob',
    label: 'Ngày sinh',
    type: 'date'
  },
  {
    key: 't_shirt_size',
    label: 'Size áo',
    type: 'text'
  },
  {
    key: 'id_card',
    label: 'ID card',
    type: 'text'
  },
  {
    key: 'emergency_contact_phone',
    label: 'SĐT liên lạc khẩn cấp',
    type: 'text'
  },
  {
    key: 'emergency_contact_name',
    label: 'Tên liên lạc khẩn cấp',
    type: 'text'
  },
  {
    key: 'gender',
    label: 'Giới tính',
    type: 'text'
  },
  {
    key: 'nationality',
    label: 'Quốc tịch',
    type: 'text'
  },
  {
    key: 'name_on_bib',
    label: 'Tên trên bib',
    type: 'text'
  },
  {
    key: 'emergency_contact_relationship',
    label: 'Mối quan hệ liên lạc khẩn cấp',
    type: 'text'
  },
  {
    key: 'allergy',
    label: 'Tiền sử bệnh/dị ứng với',
    type: 'text'
  },
  {
    key: 'medicine',
    label: 'Loại thuốc đang dùng',
    type: 'text'
  },
  {
    key: 'blood_type',
    label: 'Nhóm máu',
    type: 'text'
  },
];

export const TAX_FORM_DATA = {
  company_name: "",
  company_address: "",
  company_tax_code: "",
  recipient_name: "",
  recipient_phone: "",
  recipient_address: ""
};

export const TAX_INFO_FIELDS = [
  {
    key: 'company_name',
    label: 'Tên công ty',
  },
  {
    key: 'recipient_name',
    label: 'Tên người nhận',
  },
  {
    key: 'company_address',
    label: 'Địa chỉ công ty',
  },
  {
    key: 'recipient_address',
    label: 'Địa chỉ email',
  },
  {
    key: 'company_tax_code',
    label: 'Mã số thuê công ty',
  },
  {
    key: 'recipient_phone',
    label: 'Số điện thoại người nhận',
  },
];

export const PHONE_CODE = [
  {
    label: 'Afghanistan +93',
    value: 'AF',
    code: '93',
  },
  {
    label: 'Aland Islands +358',
    value: 'AX',
    code: '358',
  },
  {
    label: 'Albania +355',
    value: 'AL',
    code: '355',
  },
  {
    label: 'Algeria +213',
    value: 'DZ',
    code: '213',
  },
  {
    label: 'AmericanSamoa +1684',
    value: 'AS',
    code: '1684',
  },
  {
    label: 'Andorra +376',
    value: 'AD',
    code: '376',
  },
  {
    label: 'Angola +244',
    value: 'AO',
    code: '244',
  },
  {
    label: 'Anguilla +1264',
    value: 'AI',
    code: '1264',
  },
  {
    label: 'Antarctica +672',
    value: 'AQ',
    code: '672',
  },
  {
    label: 'Antigua and Barbuda +1268',
    value: 'AG',
    code: '1268',
  },
  {
    label: 'Argentina +54',
    value: 'AR',
    code: '54',
  },
  {
    label: 'Armenia +374',
    value: 'AM',
    code: '374',
  },
  {
    label: 'Aruba +297',
    value: 'AW',
    code: '297',
  },
  {
    label: 'Australia +61',
    value: 'AU',
    code: '61',
  },
  {
    label: 'Austria +43',
    value: 'AT',
    code: '43',
  },
  {
    label: 'Azerbaijan +994',
    value: 'AZ',
    code: '994',
  },
  {
    label: 'Bahamas +1242',
    value: 'BS',
    code: '1242',
  },
  {
    label: 'Bahrain +973',
    value: 'BH',
    code: '973',
  },
  {
    label: 'Bangladesh +880',
    value: 'BD',
    code: '880',
  },
  {
    label: 'Barbados +1246',
    value: 'BB',
    code: '1246',
  },
  {
    label: 'Belarus +375',
    value: 'BY',
    code: '375',
  },
  {
    label: 'Belgium +32',
    value: 'BE',
    code: '32',
  },
  {
    label: 'Belize +501',
    value: 'BZ',
    code: '501',
  },
  {
    label: 'Benin +229',
    value: 'BJ',
    code: '229',
  },
  {
    label: 'Bermuda +1441',
    value: 'BM',
    code: '1441',
  },
  {
    label: 'Bhutan +975',
    value: 'BT',
    code: '975',
  },
  {
    label: 'Bolivia, Plurinational State of +591',
    value: 'BO',
    code: '591',
  },
  {
    label: 'Bosnia and Herzegovina +387',
    value: 'BA',
    code: '387',
  },
  {
    label: 'Botswana +267',
    value: 'BW',
    code: '267',
  },
  {
    label: 'Brazil +55',
    value: 'BR',
    code: '55',
  },
  {
    label: 'British Indian Ocean Territory +246',
    value: 'IO',
    code: '246',
  },
  {
    label: 'Brunei Darussalam +673',
    value: 'BN',
    code: '673',
  },
  {
    label: 'Bulgaria +359',
    value: 'BG',
    code: '359',
  },
  {
    label: 'Burkina Faso +226',
    value: 'BF',
    code: '226',
  },
  {
    label: 'Burundi +257',
    value: 'BI',
    code: '257',
  },
  {
    label: 'Cambodia +855',
    value: 'KH',
    code: '855',
  },
  {
    label: 'Cameroon +237',
    value: 'CM',
    code: '237',
  },
  {
    label: 'Canada +1',
    value: 'CA',
    code: '1',
  },
  {
    label: 'Cape Verde +238',
    value: 'CV',
    code: '238',
  },
  {
    label: 'Cayman Islands + 345',
    value: 'KY',
    code: ' 345',
  },
  {
    label: 'Central African Republic +236',
    value: 'CF',
    code: '236',
  },
  {
    label: 'Chad +235',
    value: 'TD',
    code: '235',
  },
  {
    label: 'Chile +56',
    value: 'CL',
    code: '56',
  },
  {
    label: 'China +86',
    value: 'CN',
    code: '86',
  },
  {
    label: 'Christmas Island +61',
    value: 'CX',
    code: '61',
  },
  {
    label: 'Cocos (Keeling) Islands +61',
    value: 'CC',
    code: '61',
  },
  {
    label: 'Colombia +57',
    value: 'CO',
    code: '57',
  },
  {
    label: 'Comoros +269',
    value: 'KM',
    code: '269',
  },
  {
    label: 'Congo +242',
    value: 'CG',
    code: '242',
  },
  {
    label: 'Congo, The Democratic Republic of the Congo +243',
    value: 'CD',
    code: '243',
  },
  {
    label: 'Cook Islands +682',
    value: 'CK',
    code: '682',
  },
  {
    label: 'Costa Rica +506',
    value: 'CR',
    code: '506',
  },
  {
    label: "Cote d'Ivoire +225",
    value: 'CI',
    code: '225',
  },
  {
    label: 'Croatia +385',
    value: 'HR',
    code: '385',
  },
  {
    label: 'Cuba +53',
    value: 'CU',
    code: '53',
  },
  {
    label: 'Cyprus +357',
    value: 'CY',
    code: '357',
  },
  {
    label: 'Czech Republic +420',
    value: 'CZ',
    code: '420',
  },
  {
    label: 'Denmark +45',
    value: 'DK',
    code: '45',
  },
  {
    label: 'Djibouti +253',
    value: 'DJ',
    code: '253',
  },
  {
    label: 'Dominica +1767',
    value: 'DM',
    code: '1767',
  },
  {
    label: 'Dominican Republic +1849',
    value: 'DO',
    code: '1849',
  },
  {
    label: 'Ecuador +593',
    value: 'EC',
    code: '593',
  },
  {
    label: 'Egypt +20',
    value: 'EG',
    code: '20',
  },
  {
    label: 'El Salvador +503',
    value: 'SV',
    code: '503',
  },
  {
    label: 'Equatorial Guinea +240',
    value: 'GQ',
    code: '240',
  },
  {
    label: 'Eritrea +291',
    value: 'ER',
    code: '291',
  },
  {
    label: 'Estonia +372',
    value: 'EE',
    code: '372',
  },
  {
    label: 'Ethiopia +251',
    value: 'ET',
    code: '251',
  },
  {
    label: 'Falkland Islands (Malvinas) +500',
    value: 'FK',
    code: '500',
  },
  {
    label: 'Faroe Islands +298',
    value: 'FO',
    code: '298',
  },
  {
    label: 'Fiji +679',
    value: 'FJ',
    code: '679',
  },
  {
    label: 'Finland +358',
    value: 'FI',
    code: '358',
  },
  {
    label: 'France +33',
    value: 'FR',
    code: '33',
  },
  {
    label: 'French Guiana +594',
    value: 'GF',
    code: '594',
  },
  {
    label: 'French Polynesia +689',
    value: 'PF',
    code: '689',
  },
  {
    label: 'Gabon +241',
    value: 'GA',
    code: '241',
  },
  {
    label: 'Gambia +220',
    value: 'GM',
    code: '220',
  },
  {
    label: 'Georgia +995',
    value: 'GE',
    code: '995',
  },
  {
    label: 'Germany +49',
    value: 'DE',
    code: '49',
  },
  {
    label: 'Ghana +233',
    value: 'GH',
    code: '233',
  },
  {
    label: 'Gibraltar +350',
    value: 'GI',
    code: '350',
  },
  {
    label: 'Greece +30',
    value: 'GR',
    code: '30',
  },
  {
    label: 'Greenland +299',
    value: 'GL',
    code: '299',
  },
  {
    label: 'Grenada +1473',
    value: 'GD',
    code: '1473',
  },
  {
    label: 'Guadeloupe +590',
    value: 'GP',
    code: '590',
  },
  {
    label: 'Guam +1671',
    value: 'GU',
    code: '1671',
  },
  {
    label: 'Guatemala +502',
    value: 'GT',
    code: '502',
  },
  {
    label: 'Guernsey +44',
    value: 'GG',
    code: '44',
  },
  {
    label: 'Guinea +224',
    value: 'GN',
    code: '224',
  },
  {
    label: 'Guinea-Bissau +245',
    value: 'GW',
    code: '245',
  },
  {
    label: 'Guyana +595',
    value: 'GY',
    code: '595',
  },
  {
    label: 'Haiti +509',
    value: 'HT',
    code: '509',
  },
  {
    label: 'Holy See (Vatican City State) +379',
    value: 'VA',
    code: '379',
  },
  {
    label: 'Honduras +504',
    value: 'HN',
    code: '504',
  },
  {
    label: 'Hong Kong +852',
    value: 'HK',
    code: '852',
  },
  {
    label: 'Hungary +36',
    value: 'HU',
    code: '36',
  },
  {
    label: 'Iceland +354',
    value: 'IS',
    code: '354',
  },
  {
    label: 'India +91',
    value: 'IN',
    code: '91',
  },
  {
    label: 'Indonesia +62',
    value: 'ID',
    code: '62',
  },
  {
    label: 'Iran, Islamic Republic of Persian Gulf +98',
    value: 'IR',
    code: '98',
  },
  {
    label: 'Iraq +964',
    value: 'IQ',
    code: '964',
  },
  {
    label: 'Ireland +353',
    value: 'IE',
    code: '353',
  },
  {
    label: 'Isle of Man +44',
    value: 'IM',
    code: '44',
  },
  {
    label: 'Israel +972',
    value: 'IL',
    code: '972',
  },
  {
    label: 'Italy +39',
    value: 'IT',
    code: '39',
  },
  {
    label: 'Jamaica +1876',
    value: 'JM',
    code: '1876',
  },
  {
    label: 'Japan +81',
    value: 'JP',
    code: '81',
  },
  {
    label: 'Jersey +44',
    value: 'JE',
    code: '44',
  },
  {
    label: 'Jordan +962',
    value: 'JO',
    code: '962',
  },
  {
    label: 'Kazakhstan +77',
    value: 'KZ',
    code: '77',
  },
  {
    label: 'Kenya +254',
    value: 'KE',
    code: '254',
  },
  {
    label: 'Kiribati +686',
    value: 'KI',
    code: '686',
  },
  {
    label: "Korea, Democratic People's Republic of Korea +850",
    value: 'KP',
    code: '850',
  },
  {
    label: 'Korea, Republic of South Korea +82',
    value: 'KR',
    code: '82',
  },
  {
    label: 'Kuwait +965',
    value: 'KW',
    code: '965',
  },
  {
    label: 'Kyrgyzstan +996',
    value: 'KG',
    code: '996',
  },
  {
    label: 'Laos +856',
    value: 'LA',
    code: '856',
  },
  {
    label: 'Latvia +371',
    value: 'LV',
    code: '371',
  },
  {
    label: 'Lebanon +961',
    value: 'LB',
    code: '961',
  },
  {
    label: 'Lesotho +266',
    value: 'LS',
    code: '266',
  },
  {
    label: 'Liberia +231',
    value: 'LR',
    code: '231',
  },
  {
    label: 'Libyan Arab Jamahiriya +218',
    value: 'LY',
    code: '218',
  },
  {
    label: 'Liechtenstein +423',
    value: 'LI',
    code: '423',
  },
  {
    label: 'Lithuania +370',
    value: 'LT',
    code: '370',
  },
  {
    label: 'Luxembourg +352',
    value: 'LU',
    code: '352',
  },
  {
    label: 'Macao +853',
    value: 'MO',
    code: '853',
  },
  {
    label: 'Macedonia +389',
    value: 'MK',
    code: '389',
  },
  {
    label: 'Madagascar +261',
    value: 'MG',
    code: '261',
  },
  {
    label: 'Malawi +265',
    value: 'MW',
    code: '265',
  },
  {
    label: 'Malaysia +60',
    value: 'MY',
    code: '60',
  },
  {
    label: 'Maldives +960',
    value: 'MV',
    code: '960',
  },
  {
    label: 'Mali +223',
    value: 'ML',
    code: '223',
  },
  {
    label: 'Malta +356',
    value: 'MT',
    code: '356',
  },
  {
    label: 'Marshall Islands +692',
    value: 'MH',
    code: '692',
  },
  {
    label: 'Martinique +596',
    value: 'MQ',
    code: '596',
  },
  {
    label: 'Mauritania +222',
    value: 'MR',
    code: '222',
  },
  {
    label: 'Mauritius +230',
    value: 'MU',
    code: '230',
  },
  {
    label: 'Mayotte +262',
    value: 'YT',
    code: '262',
  },
  {
    label: 'Mexico +52',
    value: 'MX',
    code: '52',
  },
  {
    label: 'Micronesia, Federated States of Micronesia +691',
    value: 'FM',
    code: '691',
  },
  {
    label: 'Moldova +373',
    value: 'MD',
    code: '373',
  },
  {
    label: 'Monaco +377',
    value: 'MC',
    code: '377',
  },
  {
    label: 'Mongolia +976',
    value: 'MN',
    code: '976',
  },
  {
    label: 'Montenegro +382',
    value: 'ME',
    code: '382',
  },
  {
    label: 'Montserrat +1664',
    value: 'MS',
    code: '1664',
  },
  {
    label: 'Morocco +212',
    value: 'MA',
    code: '212',
  },
  {
    label: 'Mozambique +258',
    value: 'MZ',
    code: '258',
  },
  {
    label: 'Myanmar +95',
    value: 'MM',
    code: '95',
  },
  {
    label: 'Namibia +264',
    value: 'NA',
    code: '264',
  },
  {
    label: 'Nauru +674',
    value: 'NR',
    code: '674',
  },
  {
    label: 'Nepal +977',
    value: 'NP',
    code: '977',
  },
  {
    label: 'Netherlands +31',
    value: 'NL',
    code: '31',
  },
  {
    label: 'Netherlands Antilles +599',
    value: 'AN',
    code: '599',
  },
  {
    label: 'New Caledonia +687',
    value: 'NC',
    code: '687',
  },
  {
    label: 'New Zealand +64',
    value: 'NZ',
    code: '64',
  },
  {
    label: 'Nicaragua +505',
    value: 'NI',
    code: '505',
  },
  {
    label: 'Niger +227',
    value: 'NE',
    code: '227',
  },
  {
    label: 'Nigeria +234',
    value: 'NG',
    code: '234',
  },
  {
    label: 'Niue +683',
    value: 'NU',
    code: '683',
  },
  {
    label: 'Norfolk Island +672',
    value: 'NF',
    code: '672',
  },
  {
    label: 'Northern Mariana Islands +1670',
    value: 'MP',
    code: '1670',
  },
  {
    label: 'Norway +47',
    value: 'NO',
    code: '47',
  },
  {
    label: 'Oman +968',
    value: 'OM',
    code: '968',
  },
  {
    label: 'Pakistan +92',
    value: 'PK',
    code: '92',
  },
  {
    label: 'Palau +680',
    value: 'PW',
    code: '680',
  },
  {
    label: 'Palestinian Territory, Occupied +970',
    value: 'PS',
    code: '970',
  },
  {
    label: 'Panama +507',
    value: 'PA',
    code: '507',
  },
  {
    label: 'Papua New Guinea +675',
    value: 'PG',
    code: '675',
  },
  {
    label: 'Paraguay +595',
    value: 'PY',
    code: '595',
  },
  {
    label: 'Peru +51',
    value: 'PE',
    code: '51',
  },
  {
    label: 'Philippines +63',
    value: 'PH',
    code: '63',
  },
  {
    label: 'Pitcairn +872',
    value: 'PN',
    code: '872',
  },
  {
    label: 'Poland +48',
    value: 'PL',
    code: '48',
  },
  {
    label: 'Portugal +351',
    value: 'PT',
    code: '351',
  },
  {
    label: 'Puerto Rico +1939',
    value: 'PR',
    code: '1939',
  },
  {
    label: 'Qatar +974',
    value: 'QA',
    code: '974',
  },
  {
    label: 'Romania +40',
    value: 'RO',
    code: '40',
  },
  {
    label: 'Russia +7',
    value: 'RU',
    code: '7',
  },
  {
    label: 'Rwanda +250',
    value: 'RW',
    code: '250',
  },
  {
    label: 'Reunion +262',
    value: 'RE',
    code: '262',
  },
  {
    label: 'Saint Barthelemy +590',
    value: 'BL',
    code: '590',
  },
  {
    label: 'Saint Helena, Ascension and Tristan Da Cunha +290',
    value: 'SH',
    code: '290',
  },
  {
    label: 'Saint Kitts and Nevis +1869',
    value: 'KN',
    code: '1869',
  },
  {
    label: 'Saint Lucia +1758',
    value: 'LC',
    code: '1758',
  },
  {
    label: 'Saint Martin +590',
    value: 'MF',
    code: '590',
  },
  {
    label: 'Saint Pierre and Miquelon +508',
    value: 'PM',
    code: '508',
  },
  {
    label: 'Saint Vincent and the Grenadines +1784',
    value: 'VC',
    code: '1784',
  },
  {
    label: 'Samoa +685',
    value: 'WS',
    code: '685',
  },
  {
    label: 'San Marino +378',
    value: 'SM',
    code: '378',
  },
  {
    label: 'Sao Tome and Principe +239',
    value: 'ST',
    code: '239',
  },
  {
    label: 'Saudi Arabia +966',
    value: 'SA',
    code: '966',
  },
  {
    label: 'Senegal +221',
    value: 'SN',
    code: '221',
  },
  {
    label: 'Serbia +381',
    value: 'RS',
    code: '381',
  },
  {
    label: 'Seychelles +248',
    value: 'SC',
    code: '248',
  },
  {
    label: 'Sierra Leone +232',
    value: 'SL',
    code: '232',
  },
  {
    label: 'Singapore +65',
    value: 'SG',
    code: '65',
  },
  {
    label: 'Slovakia +421',
    value: 'SK',
    code: '421',
  },
  {
    label: 'Slovenia +386',
    value: 'SI',
    code: '386',
  },
  {
    label: 'Solomon Islands +677',
    value: 'SB',
    code: '677',
  },
  {
    label: 'Somalia +252',
    value: 'SO',
    code: '252',
  },
  {
    label: 'South Africa +27',
    value: 'ZA',
    code: '27',
  },
  {
    label: 'South Sudan +211',
    value: 'SS',
    code: '211',
  },
  {
    label: 'South Georgia and the South Sandwich Islands +500',
    value: 'GS',
    code: '500',
  },
  {
    label: 'Spain +34',
    value: 'ES',
    code: '34',
  },
  {
    label: 'Sri Lanka +94',
    value: 'LK',
    code: '94',
  },
  {
    label: 'Sudan +249',
    value: 'SD',
    code: '249',
  },
  {
    label: 'Suriname +597',
    value: 'SR',
    code: '597',
  },
  {
    label: 'Svalbard and Jan Mayen +47',
    value: 'SJ',
    code: '47',
  },
  {
    label: 'Swaziland +268',
    value: 'SZ',
    code: '268',
  },
  {
    label: 'Sweden +46',
    value: 'SE',
    code: '46',
  },
  {
    label: 'Switzerland +41',
    value: 'CH',
    code: '41',
  },
  {
    label: 'Syrian Arab Republic +963',
    value: 'SY',
    code: '963',
  },
  {
    label: 'Taiwan +886',
    value: 'TW',
    code: '886',
  },
  {
    label: 'Tajikistan +992',
    value: 'TJ',
    code: '992',
  },
  {
    label: 'Tanzania, United Republic of Tanzania +255',
    value: 'TZ',
    code: '255',
  },
  {
    label: 'Thailand +66',
    value: 'TH',
    code: '66',
  },
  {
    label: 'Timor-Leste +670',
    value: 'TL',
    code: '670',
  },
  {
    label: 'Togo +228',
    value: 'TG',
    code: '228',
  },
  {
    label: 'Tokelau +690',
    value: 'TK',
    code: '690',
  },
  {
    label: 'Tonga +676',
    value: 'TO',
    code: '676',
  },
  {
    label: 'Trinidad and Tobago +1868',
    value: 'TT',
    code: '1868',
  },
  {
    label: 'Tunisia +216',
    value: 'TN',
    code: '216',
  },
  {
    label: 'Turkey +90',
    value: 'TR',
    code: '90',
  },
  {
    label: 'Turkmenistan +993',
    value: 'TM',
    code: '993',
  },
  {
    label: 'Turks and Caicos Islands +1649',
    value: 'TC',
    code: '1649',
  },
  {
    label: 'Tuvalu +688',
    value: 'TV',
    code: '688',
  },
  {
    label: 'Uganda +256',
    value: 'UG',
    code: '256',
  },
  {
    label: 'Ukraine +380',
    value: 'UA',
    code: '380',
  },
  {
    label: 'United Arab Emirates +971',
    value: 'AE',
    code: '971',
  },
  {
    label: 'United Kingdom +44',
    value: 'GB',
    code: '44',
  },
  {
    label: 'United States +1',
    value: 'US',
    code: '1',
  },
  {
    label: 'Uruguay +598',
    value: 'UY',
    code: '598',
  },
  {
    label: 'Uzbekistan +998',
    value: 'UZ',
    code: '998',
  },
  {
    label: 'Vanuatu +678',
    value: 'VU',
    code: '678',
  },
  {
    label: 'Venezuela, Bolivarian Republic of Venezuela +58',
    value: 'VE',
    code: '58',
  },
  {
    label: 'Vietnam +84',
    value: 'VN',
    code: '84',
  },
  {
    label: 'Virgin Islands, British +1284',
    value: 'VG',
    code: '1284',
  },
  {
    label: 'Virgin Islands, U.S. +1340',
    value: 'VI',
    code: '1340',
  },
  {
    label: 'Wallis and Futuna +681',
    value: 'WF',
    code: '681',
  },
  {
    label: 'Yemen +967',
    value: 'YE',
    code: '967',
  },
  {
    label: 'Zambia +260',
    value: 'ZM',
    code: '260',
  },
  {
    label: 'Zimbabwe +263',
    value: 'ZW',
    code: '263',
  },
];

export const ROLE_VALUES = {
  VIEW: "view",
  RESEND_PAYMENT_EMAIL: "resend_payment_email",
  EDIT_CUSTOMER_INFO: "edit_customer_info",
  REFUND: "refund",
  VAT_ACTION: "vat_action",
  EDIT_PARTICIPANT_INFO: "edit_participant_info",
  EXPORT_ACTIONS: "export_actions",
  CHANGE_STATUS: "change_status",
  RESEND_QR_CODE_EMAIL: "9"
}