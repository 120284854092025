import React, { } from "react";
import "./App.css";
import { Route, Switch, Redirect } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import Login from "./pages/Login/Login";
import Orders from "./pages/Orders/Orders";
import Users from "./pages/Users/Users";
import ChangePassword from "./pages/Profile/ChangePassword/ChangePassword";
import OrderDetail from "./pages/Orders/OrderDetail/OrderDetail";
import CreateUser from "./pages/Users/CreateUser/CreateUser";
import EditUser from "./pages/Users/EditUser/EditUser";
import Index from "./pages/Index";
import Contacts from "./pages/Contacts/Contacts";
import { getCurrentUser } from "./util/auth";
import { isEmpty } from "lodash";
import { checkPermission } from "./util/helpers";
import { ROLE_VALUES } from "./util/constants";

const PrivateRoute = ({ component: Component, authObject = '', ...rest }) => {
  const user = getCurrentUser();
  return (
    <Route {...rest} render={(props) => (
      !isEmpty(user)
        ? (isEmpty(authObject) 
          ? <Component {...props} /> 
          : (checkPermission(authObject, ROLE_VALUES.VIEW) 
            ? <Component {...props} /> 
            : <Redirect to='/index' />))
        : <Redirect to='/login' />
    )} />
  );
}
function App() {

  return (
    <>
      <Switch>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/">
          <Redirect to="/index" />      
        </Route>
        <PrivateRoute exact path='/orders' authObject="list" component={Orders} />
        <PrivateRoute path='/orders/:code' authObject="list" component={OrderDetail} />
        <PrivateRoute exact path='/contacts' authObject="contact" component={Contacts} />
        <PrivateRoute exact path='/users' authObject="user" component={Users} />
        <PrivateRoute path='/users/create' authObject="user" component={CreateUser} />
        <PrivateRoute path='/users/:id' authObject="user" component={EditUser} />
        <PrivateRoute exact path='/profile/password' component={ChangePassword} />
        <PrivateRoute exact path='/index' component={Index} />
      </Switch>
      <ToastContainer />
    </>
  );
}

export default App;
