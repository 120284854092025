import axiosClient from "../axiosClient";

const userAPI = {
  getUsers: () => {
    const url = "/cs/user-admin";
    return axiosClient.get(url);
  },
  getUserDetail: (params, id) => {
    const url = `/cs/user-admin/${id}`;
    return axiosClient.get(url, params);
  },
  createUser: (params) => {
    const url = "/cs/user-admin/register";
    return axiosClient.post(url, params);
  },
  resetPassword: (id) => {
    const url = `/cs/user-admin/${id}/password/reset`;
    return axiosClient.put(url);
  },
  updatePassword: (id, params) => {
    const url = `/cs/user-admin/${id}/password`;
    return axiosClient.put(url, params);
  },
  activation: (isActice, id) => {
    const url = `/cs/user-admin/${id}/active/${isActice}`;
    return axiosClient.put(url);
  },
  updateUserRole: (params, id) => {
    const url = `/cs/user-admin/${id}/role`;
    return axiosClient.put(url, params);
  },
  
  login: (params) => {
    const url = "cs/user-admin/login";
    return axiosClient.post(url, params);
  },
};

export default userAPI;
