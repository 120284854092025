import { Button, Col, Modal, Row, Select } from "antd";
import { get, isEmpty } from "lodash";
import React, { useState } from "react";
import { ATTENDANTS_EDIT_FIELDS, ROLE_VALUES, PHONE_CODE } from "../../../../../util/constants";
import { checkPermission } from "../../../../../util/helpers";
import * as classes from '../../OrderDetail.module.css';

const { Option } = Select;

const AttendantModal = ({ isOpen, onCancel, attendant, _handleAttendantFieldChange, _handleUpdateAttendantInfo }) => {
  const [isLoading, setIsLoading] = useState(false);

  const _handleSubmit = async () => {
    setIsLoading(true);
    const response = await _handleUpdateAttendantInfo(attendant);
    if (response) {
      onCancel();
    }
    setIsLoading(false)
  }

  return !isEmpty(attendant) ? (
    <Modal    
      title="Thông tin vận động viên"
      visible={isOpen}
      footer={null}
      closable={true}
      mask={false}
      maskClosable={true}
      onCancel={onCancel}
      width={700}
      centered
    >
      <Row gutter={[16, 16]} className={classes.infoContainer}>
        {ATTENDANTS_EDIT_FIELDS.map((field, index) => (
          <Col span={12} key={index}>
            <div style={{height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 8}}>
              <span style={{fontWeight: '600'}}>{field.label}:</span>
              {field.key.includes('phone') ? (
                <Select
                  showSearch
                  allowClear
                  placeholder="Mã vùng SĐT"
                  className={classes.areaPhoneCode}
                  listHeight={128}
                  style={{ width: 'auto', minWidth: 100, maxWidth: '50%' }}
                  value={get(attendant, `${field.key}_area_code`, null)}
                  onChange={(value) => _handleAttendantFieldChange(`${field.key}_area_code`, value, attendant.participant_id)}
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  disabled={!checkPermission('list', ROLE_VALUES.EDIT_PARTICIPANT_INFO) || isEmpty(attendant.participant_id)} 
                >
                  {PHONE_CODE.map((data, index) => (
                    <Option key={index} value={data.code}>{data.label}</Option>
                  ))}
                </Select>
              ) : null}
            </div>
            <div>
              <input 
                disabled={!checkPermission('list', ROLE_VALUES.EDIT_PARTICIPANT_INFO) || isEmpty(attendant.participant_id)} 
                className={`${classes.buyerLabel} form-control`}
                type={field.type} 
                name={field.key}
                id={field.key}
                value={attendant[field.key]} 
                onChange={(e) => _handleAttendantFieldChange(field.key, e.target.value, attendant.participant_id)}
              />
            </div>
          </Col>
        ))}
        <Col span={12}>
          <div style={{height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 8}}>
            <span style={{fontWeight: '600'}}>Số ghế:</span>
          </div>
          <div className={`${classes.buyerLabel} form-control`} style={{backgroundColor: "#e9ecef"}}>
            {attendant["seat_number"]}
          </div>
        </Col>
        <Col span={12}>
          <div style={{height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 8}}>
            <span style={{fontWeight: '600'}}>Mã tham chiếu:</span>
          </div>
          <div className={`${classes.buyerLabel} form-control`} style={{backgroundColor: "#e9ecef"}}>
            {attendant["ticket_no"]}
          </div>
        </Col>

        {(checkPermission('list', ROLE_VALUES.EDIT_PARTICIPANT_INFO) && !isEmpty(attendant.participant_id)) && 
        <Col span={24} className="text-center mt-2">
          <Button 
            type="primary" 
            loading={isLoading} 
            style={{width: 100, height: 40, fontSize: 14}}
            onClick={_handleSubmit}
          >
            Save
          </Button>
        </Col>}
      </Row>
    </Modal>
  ) : null
}

export default AttendantModal;