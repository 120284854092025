import React from 'react';
import { withRouter } from "react-router-dom";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Button } from "antd";

const PermissionDeniedModal = (props) => {
  const{ isOpen, toggle } = props;
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalBody>
        Permission Denied: Access is denied due to invalid credentials
      </ModalBody>
      <ModalFooter>
        <Button
          style={{
            backgroundColor: "cornflowerblue",
            border: "1px solid cornflowerblue",
          }}
          onClick={() => props.history.push("/index")}
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default withRouter(PermissionDeniedModal);