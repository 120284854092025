import { Col, Row, Select } from 'antd';
import React, { useState } from 'react';
import * as classes from '../OrderDetail.module.css';

import {
  EditTwoTone,
  SaveTwoTone,
} from '@ant-design/icons';
import { get } from 'lodash';
import { checkPermission } from '../../../../util/helpers';
import { ROLE_VALUES, PHONE_CODE } from '../../../../util/constants';

const { Option } = Select;

const BuyerInfo = ({ orderDetail, _handleBuyerInfoFieldChange, _updateBuyerInfo }) => {
  const [isEditBuyer, setIsEditBuyer] = useState(false);

  const _handleEditBuyer = async () => {
    if (!isEditBuyer) return setIsEditBuyer(true);
    const response = await _updateBuyerInfo();
    if (response) {
      setIsEditBuyer(false);
    }
  }
  return (
    <Row gutter={[16, 16]} className={classes.infoContainer}>
      <Col 
        className={classes.label} 
        span={24}
      >
        Thông tin khách hàng
        {checkPermission('list', ROLE_VALUES.EDIT_CUSTOMER_INFO) ? (
          <div 
            style={{display: 'flex', alignItems: 'center'}} 
            onClick={_handleEditBuyer}
          >
            {isEditBuyer 
            ? <SaveTwoTone className={classes.editBuyerIcon} />  
            : <EditTwoTone className={classes.editBuyerIcon} />}
          </div>
        ) : null}
      </Col>

      <Col span={12}>
        Họ và tên :
        {isEditBuyer ? (
          <input 
            className={classes.editInput} 
            placeholder="Họ và tên" 
            type="text" 
            value={orderDetail.buyer.name}
            onChange={(e) => _handleBuyerInfoFieldChange('name', e.target.value)} 
          />
        ) : (
          <span className={classes.buyerLabel}> {orderDetail.buyer.name} </span>
        )}
      </Col>
      <Col span={12}>
        Email :
        {isEditBuyer ? (
          <input 
            className={classes.editInput} 
            placeholder="Email" 
            type="text" 
            value={orderDetail.buyer.email}
            onChange={(e) => _handleBuyerInfoFieldChange('email', e.target.value)} 
          />
        ) : (
          <>
            <span className={classes.buyerLabel}> {orderDetail.buyer.email} </span>
            { orderDetail.order_status === 'dat_hang_thanh_cong' ?
              <span>
                { orderDetail.is_sent_email_success === true ? 
                  <span style={{color: '#1890ff'}}>
                    (Đã gửi email)
                  </span> : 
                  <span>
                    (Chưa gửi email)
                  </span>
                }
              </span>
              : null
            }
          </>
        )}
      </Col>

      <Col span={12}>
        Phone :
        {isEditBuyer ? (
          <>
            <Select
              showSearch
              allowClear
              placeholder="Mã vùng SĐT"
              className={classes.paymentMethod}
              style={{ width: 'auto', minWidth: 75, marginLeft: 6 }}
              value={get(orderDetail, 'buyer.phone_area_code', null)}
              onChange={(value) => _handleBuyerInfoFieldChange('phone_area_code', value)}
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {PHONE_CODE.map((data, index) => (
                <Option key={index} value={data.code}>{data.label}</Option>
              ))}
            </Select>
            <input 
              className={classes.editInput} 
              placeholder="Phone" 
              type="text" 
              value={orderDetail.buyer.phone}
              onChange={(e) => _handleBuyerInfoFieldChange('phone', e.target.value)} 
            />
          </>
        ) : (
          <span className={classes.buyerLabel}> {get(orderDetail, 'buyer.phone_area_code', '') && `(+${get(orderDetail, 'buyer.phone_area_code', '')})`} {orderDetail.buyer.phone} </span>
        )}
      </Col>
      {orderDetail.order_type === "group_buy"  ? 
        <Col span={12}>
        Tên nhóm: 
        <span className={classes.buyerLabel}> {get(orderDetail, 'extra.checkout_data.club_name', '')} </span>
      </Col> : null }
    </Row>
  )
}

export default BuyerInfo;