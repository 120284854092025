const getUser = () => {
  try {
    const user = localStorage.getItem("user");
    return JSON.parse(user) || {};
  } catch (error) {
    return {};
  }
};

export const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const getToken = () => {
  const accestoken = localStorage.getItem("token");
  return accestoken || "";
};

export const isLoggedIn = () => {
  const user = getUser();
  return Object.keys(user).length > 0;
};

export const getCurrentUser = () => getUser();

export const logout = (callback) => {
  console.log(`Ensuring the \`gatsbyUser\` property exists.`);
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};
