import axiosClient from "../axiosClient";

const eventAPI = {
  getEvents: () => {
    const url = `/admin/events`;
    return axiosClient.get(url);
  },
};

export default eventAPI;
