import React, { useState } from "react";
import { Modal, Button } from "antd";
import { TAX_FORM_DATA, TAX_INFO_FIELDS } from "../../../../../util/constants";
import { isEmpty } from "lodash";
import { toast } from 'react-toastify';

const TaxModal = ({ isOpen, onCancel, _submitTaxData }) => {

  const [taxData, setTaxData] = useState(TAX_FORM_DATA);
  const [isLoading, setIsLoading] = useState(false);

  const _handleTaxInputChange = (key, value) => {
    const updatedTaxData = {...taxData};
    updatedTaxData[key] = value;
    setTaxData(updatedTaxData);
  };
  
  const _submit = async () => {
    for(var key in taxData) {
      if(isEmpty(taxData[key])) {
        const errorMessage = 'Vui lòng nhập đầy đủ thông tin';
        return toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }) 
      }
    }
    setIsLoading(true);
    await _submitTaxData(taxData);
    setIsLoading(false);
  }

  return (
    <Modal       
      title="Thông tin VAT"
      visible={isOpen}
      footer={null}
      closable={true}
      mask={false}
      maskClosable={true}
      onCancel={onCancel}
      width={500}
      centered
    >
      {TAX_INFO_FIELDS.map((field, index) => (
        <div className="form-group" key={index}>
          <label htmlFor={field.key}>{field.label} <span style={{color: '#ec1b30'}}>*</span> </label>
          <input 
            type="text" 
            className="form-control" 
            id={field.key} 
            value={taxData[field.key]} onChange={(e) => _handleTaxInputChange(field.key, e.target.value)}
          />
        </div>
      ))}
      <div className="text-center">
        <Button 
          type="primary" 
          loading={isLoading} 
          style={{width: 100, height: 40, fontSize: 14}}
          onClick={_submit}
        >
          Lưu
        </Button>
      </div>
    </Modal>
  )
}

export default TaxModal;