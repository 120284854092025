import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { getCurrentUser, logout } from "../../../util/auth";
import { Input } from "antd";
import { toast, ToastContainer } from 'react-toastify'
import userAPI from "../../../api/UserAPI/userAPI";
import Layout from "../../../components/Layout";

const ChangePassword = (props) => {

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const user = getCurrentUser();

  const handleSave = async () => {
    try {
      if (!password) {
        return toast.warn('Please fill in your password.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
      if (!newPassword) {
        return toast.warn('Please fill in your new paosword.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
      const params = {
        password: password,
        new_password: newPassword
      }

      console.log(user)

      const response = await userAPI.updatePassword(
        user.result.id,
        params
      );
      console.log(response);
      if (response) {
        toast.success('Password Changed Successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
        handleLogout();
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error.response.data.error.message || 'Error has occurred'
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })

      console.log("Faile to fetch", error);
    }
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <Layout>
      <Form
        style={{ width: "50%", marginTop: 60, marginLeft:'auto', marginRight:'auto' , textAlign: "webkit-center" }}
      >
        <FormGroup row>
          <Label for="exampleEmail" sm={2}>
            Password <span style={{color: '#dc3545'}}>*</span>
          </Label>
          <Col sm={10}>
            <Input.Password
              id="password"
              name="password"
              type="password"
              placeholder="Enter password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              // value={this.user.result.password}
              // onChange={this.handleFormChange}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label style={{whiteSpace:"nowrap"}} for="exampleEmail" sm={2}>
            New Password <span style={{color: '#dc3545'}}>*</span>
          </Label>
          <Col sm={10}>
            <Input.Password
              id="new_password"
              name="new_password"
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Col>
        </FormGroup>

        <FormGroup check row>
          <Col sm={{ size: 10, offset: 2 }} style={{padding: 0}}>
            <Button onClick={handleSave.bind(this)}>
            Submit</Button>
          </Col>
        </FormGroup>
      </Form>
      <ToastContainer />
    </Layout>
  );
}
export default withRouter(ChangePassword);
